import React, { useState, useEffect, createRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import Api from '../../api/global.service';
import 'bootstrap/dist/css/bootstrap.css';
import BlockUi from 'react-block-ui';
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectApi from '../../nuup/componentes/Forms/FormSelectApi.jsx';
import { subscribe, unsubscribe } from '../../components/events';
import { Link, useParams } from 'react-router-dom';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import ModalFirma from '../acopios/entregas-x-productor/modal-firma.jsx';
import { convertFileToDataUrl } from '../../utils/convertImages';

const sharedNameForm = 'Usuario';
const sharedTable = 'users';
const sharedURL = '/admin/usuarios/list';

const UsuariosCRUD = () => {
  const { uuid } = useParams();
  const [name] = useState('Usuarios');
  const [nameForm] = useState(sharedNameForm);
  const [table] = useState(sharedTable);
  const [url] = useState(sharedURL);

  const [blocking, setBlocking] = useState(false);

  const [IdRol, setIdRol] = useState(0);
  const [IdCooperativa, setIdCooperativa] = useState(0);
  const [IdAcopio, setIdAcopio] = useState(0);
  const [nombreCompleto, setNombreCompleto] = useState({nombres: "", apellido_paterno: "", apellido_materno: ""});
  const [firma, setFirma] = useState(null);

  const dialog = createRef();
  const fileUpload = createRef();

  const handleFirma = (event) => {
    setFirma(event.detail);
  };

  const updateImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      convertFileToDataUrl(file, setFirma);
    }
  };

  const handleSelectFile = () => {
    fileUpload.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      setFirma(file);
      convertFileToDataUrl(file, setFirma);
    }
  };

  const schema = yup.object().shape({
    email: yup.string().required('El correo es requerido'),
    username: yup.string().required('El teléfono es requerido'),
    nombres: yup.string().required('Nombres es requerido'),
    apellido_paterno: yup.string().required('Apellido paterno es requerido'),
    password: uuid == null ? yup.string().required('Contraseña es requerido') : yup.string(),
    id_rol: yup.number().required().min(1, 'Rol es requerido'),
    id_cooperativa: yup.number().required().min(1, 'Cooperativa es requerido'),
    id_centros_acopios: yup.number().required('Acopio es requerido'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: async (data, context, options) => {
      data.id_centros_acopios = IdAcopio;
      return yupResolver(schema)(data, context, options)
    },
    mode: 'onBlur',
    defaultValues: {
      email: '',
      username: '',
      password: '',
      nombres: '',
      apellido_paterno: '',
      apellido_materno: '',
      foto: '',
      id_rol: null,
      id_cooperativa: null,
      id_centros_acopios: 0,
      firma: '',
    },
  });

  useEffect(() => {
    if (uuid != null) {
      setBlocking(true);
      Api.get('users', uuid)
        .then(response => {
          const userData = response.data[0];
          userData.password = '';
          reset(userData);
          setIdRol(userData.id_rol);
          setIdCooperativa(userData.id_cooperativa);
          setNombreCompleto({
            nombres: userData.nombres,
            apellido_paterno: userData.apellido_paterno,
            apellido_materno: userData.apellido_materno
          });
          setFirma(userData.firma || '');
          setBlocking(false);
        })
        .catch(() => setBlocking(false));
    }
    subscribe('Firma::onSave', handleFirma);
    return () => unsubscribe('Firma::onSave');
  }, []);

  const onSubmit = values => {
    const item = {
      email: values.email,
      username: values.username,
      password: values.password,
      nombres: values.nombres,
      apellido_paterno: values.apellido_paterno,
      apellido_materno: values.apellido_materno,
      foto: values.foto,
      id_rol: IdRol,
      id_cooperativa: IdCooperativa,
      id_centros_acopios: IdAcopio,
      firma: firma,
    };

    setBlocking(true);
    const request = uuid == null ? Api.create(table, item) : Api.update(table, uuid, item);

    request
      .then(response => {
        const data = response.data[0];
        setBlocking(false);
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Se ${uuid == null ? 'registró' : 'actualizó'} con éxito la/el ${nameForm.toLowerCase()} -> ${data.uuid}`,
          allowOutsideClick: false,
        }).then(res => {
          if (res.isConfirmed) {
            window.location.href = url;
          }
        })
      })
      .catch(e => {
        setBlocking(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        });
      });
  };

  return (
    <>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/usuarios/list">Usuarios</Link>
          </li>
          <li className="breadcrumb-item active">{uuid == null ? 'Nuevo' : 'Editar'}</li>
        </ol>
        <h1 className="page-header">
          {name} / {uuid == null ? 'Nuevo' : 'Editar'}
        </h1>

        <div className="row">
          <div className="col-md-12">
            <Panel>
              <PanelBody>
                <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-12">
                      <FormInput id="uuid" type="hidden" name="uuid" label="UUID" error={errors.uuid} register={register} required />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <FormInput
                          id="nombres"
                          type="text"
                          name="nombres"
                          label="Nombre(s)"
                          error={errors.nombres}
                          register={register}
                          onChange={(event) => setNombreCompleto({...nombreCompleto, nombres: event.target.value})}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          id="apellido_paterno"
                          type="text"
                          name="apellido_paterno"
                          label="Apellido Paterno"
                          error={errors.apellido_paterno}
                          register={register}
                          onChange={(event) => setNombreCompleto({...nombreCompleto, apellido_paterno: event.target.value})}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          id="apellido_materno"
                          type="text"
                          name="apellido_materno"
                          label="Apellido Materno"
                          error={errors.apellido_materno}
                          register={register}
                          onChange={(event) => setNombreCompleto({...nombreCompleto, apellido_materno: event.target.value})}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <FormInput
                          id="email"
                          type="text"
                          name="email"
                          label="Correo"
                          error={errors.email}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormInput id="username" type="text" name="username" label="Teléfono" error={errors.username} register={register} />
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          id="password"
                          type="text"
                          name="password"
                          label="Contraseña"
                          error={errors.password}
                          register={register}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <FormSelectApi
                          id="id_rol"
                          name="id_rol"
                          label="Rol"
                          url="roles"
                          keyArray="id"
                          labelArray="nombre"
                          showLoading={false}
                          onChange={e => {
                            setIdRol(e.target.value);
                          }}
                          error={errors.id_rol}
                          onFinish={() => { }}
                          register={register}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormSelectApi
                          id="id_cooperativa"
                          name="id_cooperativa"
                          label="Cooperativa"
                          url="cooperativas"
                          keyArray="id"
                          labelArray="nombre"
                          showLoading={false}
                          onChange={e => {
                            setIdCooperativa(e.target.value);
                          }}
                          error={errors.id_cooperativa}
                          onFinish={() => { }}
                          register={register}
                        />
                      </div>
                      {IdRol == 6 && (
                        <div className="col-md-4">
                          <FormSelectApi
                            id="id_centros_acopios"
                            name="id_centros_acopios"
                            label="Centro de Acopio"
                            url="centros_acopios"
                            keyArray="id"
                            labelArray="nombre"
                            showLoading={false}
                            onChange={e => {
                              setIdAcopio(e.target.value);
                            }}
                            error={errors.id_centros_acopios}
                            onFinish={(items) => {
                              if (items.length === 1) {
                                setIdAcopio(items[0].id);
                              }
                            }}
                            register={register}
                            filter={`[{ column: 'id_cooperativa', operator: '=', value: auth.user.id_cooperativa }]`}
                            column="id_cooperativa"
                            value={IdCooperativa}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <FormInput
                          id="foto"
                          type="text"
                          name="foto"
                          label="Foto"
                          error={errors.foto}
                          register={register}
                        />
                      </div>
                      <ModalFirma ref={dialog} register={register} title="Generar firma" productor_nombre={`${nombreCompleto.nombres || ''} ${nombreCompleto.apellido_paterno || ''} ${nombreCompleto.apellido_materno || ''}`.trim()} />

                      <div className="grid col-md-2">
                        <button
                          onClick={() => {
                            dialog.current.show();
                            setFirma(null);
                          }}
                          type="button"
                          style={{ marginTop: 0, display: 'grid', alignSelf: 'center' }}
                          className="btn btn-outline-secondary btn-block"
                        >
                          Generar firma
                        </button>
                      </div>
                      <input
                        type="file"
                        ref={fileUpload}
                        className="btn btn-outline-secondary"
                        onChange={updateImage}
                        accept="image/png"
                        style={{ display: 'none' }}
                      />
                      <div className="grid col-md-2">
                        <button
                          onClick={() => {
                            setFirma(null);
                            handleSelectFile();
                          }}
                          onDragOver={handleDragOver}
                          onDrop={handleFileDrop}
                          type="button"
                          className="btn btn-outline-secondary btn-sirio mr-2"
                          style={{ borderRadius: '20px' }}
                        >
                          <i className="fa fa-upload mr-2"></i> Subir firma
                        </button>
                      </div>
                      <div className="col-md-2">
                        <button className="btn btn-primary btn-block" type="submit">
                          Guardar
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="row mt-2">
                    <div
                      className="col-xl-12 resumen-entrega-firma grid text-center mt-4"
                      style={{ borderBottom: 'none' }}
                      onDragOver={handleDragOver}
                      onDrop={handleFileDrop}
                    >
                      {firma ? (
                        <img
                          src={firma.length > 50 ? firma : `${process.env.REACT_APP_API_URL}${firma}`}
                          id="firma"
                          alt="Firma generada"
                          style={{
                            display: 'block',
                            margin: '0 auto',
                            width: '200px',
                            height: '125px',
                          }}
                        />
                      ) : (
                        <span
                          style={{
                            alignSelf: 'center',
                            border: '3px dashed LightGray',
                            padding: '15px',
                            fontSize: '20px',
                            verticalAlign: 'middle',
                          }}
                        >
                          Arrastra tu firma aquí
                        </span>
                      )}
                    </div>
                  </div>
                </BlockUi>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsuariosCRUD;
