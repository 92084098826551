import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../../components/card/card-container.jsx';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb.jsx';
import Api from '../../../api/global.service';
import { useAuth } from '../../../components/content/auth.jsx';
import env from '../../../environment.js';
import { subscribe, unsubscribe } from '../../../components/events';
import EntregasXProductorFirma from './entregas-x-productor-firma-pago-pendiente.js';
import EntregasXProductorInfo from './entregas-x-productor-info-acopio.js';
import EntregasXProductorBuscador from './entregas-x-productor-buscador.js';
import './entregas-x-productor.css';
import ExampleCustomInput from '../../../components/modal/input.jsx';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';

import { useParams } from 'react-router-dom';
import Select from 'react-select';
import formatMoneyNumber, { formatNumber } from '../../../utils/formatNumber.js';
import { es } from 'date-fns/locale';
import { useCoopConfig } from '../../../components/content/coop-config.jsx';

var curr = new Date();

const EntregasXProductorPagoPendiente = () => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const { id_cuenta, uuid_cuenta, id_acopio } = useParams();
  const [date, setDate] = useState(null);
  const [dataForm, setDataForm] = useState({});
  const [showForm, setShowForm] = useState(true);
  const [showFirma, setShowFirma] = useState(false);
  const [metodosPago, setMetodosPago] = useState([]);
  const [pendientePago, setPendientePago] = useState(null);
  const [entregas, setEntregas] = useState();
  const [pagos, setPagos] = useState();
  const [blocking, setBlocking] = useState(true);
  const [cuenta, setCuenta] = useState();

  const customStyles = {
    control: base => ({
      ...base,
      flexDirection: 'row-reverse',
    }),
    clearIndicator: base => ({
      ...base,
      position: 'absolute',
      right: 0,
    }),
  };

  useEffect(() => {
    getMetodosPago();
  }, []);

  useEffect(() => {
    var curr = new Date();
    curr.setDate(curr.getDate());
    setDate(curr.toISOString().substring(0, 10));

    subscribe('Entrega::onReturn', e => handleReturn(e));
    subscribe('Entrega::Entregas', e => handleEntregas(e));
    subscribe('Entrega::Pagos', e => handlePagos(e));
    subscribe('Entrega::Cuenta', e => handleCuenta(e));
    return () => {
      unsubscribe('Entrega::onReturn');
      unsubscribe('Entrega::Entregas');
      unsubscribe('Entrega::Pagos');
    };
  }, []);

  const handleCuenta = event => {
    setCuenta(event.detail);
  };

  const handleEntregas = event => {
    setEntregas(event.detail);
  };

  const handlePagos = event => {
    setPagos(event.detail);
    let pendientePago = getSaldoEntregas(
      event.detail?.pagos_total || 0,
      event.detail?.pagos_descuentos_total || 0,
      event.detail?.pagos_pagado_total || 0
    );
    setPendientePago(pendientePago);
  };

  const handleReturn = event => {
    setShowForm(true);
    setShowFirma(false);
  };

  const [formData] = useState({
    id_acopio: id_acopio,
    id_cuenta: id_cuenta,
    id_ciclo: localStorage.getItem(`${env.appName}-ciclo_id`),
    fecha_ejecucion: curr,
    id_metodo_pago: '',
    monto_pagado: '',
  });

  const schema = yup.object().shape({
    id_acopio: yup.number().min(0),
    id_cuenta: yup.number().min(1),
    fecha_ejecucion: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required('Información obligatoria'),
    id_metodo_pago: yup.mixed().test({
      message: 'Información obligatoria',
      test: val => val.value > 0,
    }),
    monto_pagado: yup
      .number()
      .min(0.01, 'El monto debe ser mayor a 0')
      .max(formatNumber(pendientePago), 'El monto no debe ser mayor a $ ' + formatMoneyNumber(pendientePago))
      .transform(value => (isNaN(value) ? undefined : value))
      .required('Información obligatoria'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: formData,
  });

  const onSubmit = values => {
    setDataForm(values);
    setShowFirma(true);
    setShowForm(false);
  };

  const getMetodosPago = () => {
    Api.getAll('acopios/catalogos/metodos_pago').then(response => {
      let _items = response.data.map(function(item) {
        return { value: item['id'], label: item['nombre'] };
      });
      setMetodosPago(_items);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setBlocking(true);
    Api.get('admin/acopios/entregas/lotes', localStorage.getItem(`${env.appName}-ciclo_id`)).then(response => {
      let _items = response.data.map(function(item) {
        return {
          value: item['id'],
          label: item['nombre'] + ' | ' + item['folio'],
          folio: item['folio'],
          volumen: item['volumen'],
        };
      });
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  const getSaldoEntregas = (pagos_total, pagos_descuentos_total, pagos_pagado_total) => {
    return (pagos_total || 0) - (pagos_descuentos_total || 0) - (pagos_pagado_total || 0);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Pago pendiente</li>
        </ol>
        <EntregasXProductorBuscador uuid_cuenta={uuid_cuenta} />
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            {showForm && (
              <CardContainer height={'100%'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-xl-6 col-lg-12">
                      <p className="titulo-input">{'Registro de pago pendiente'}</p>

                      <Controller
                        control={control}
                        name="fecha_ejecucion"
                        render={({ field }) => (
                          <DatePicker
                            customInput={<ExampleCustomInput />}
                            dateFormat={'dd/MMM/yyyy'}
                            onChange={date => field.onChange(date)}
                            selected={field.value}
                            wrapperClassName="w-100"
                            locale={es}
                          />
                        )}
                      />
                      {errors?.fecha_ejecucion && <div className="invalid-feedback">{errors?.fecha_ejecucion.message}</div>}
                    </div>
                  </div>

                  <div className="row pt-3 pl-2">
                    <p className="titulo-input ml-1">{'Datos de pago pendiente'}</p>
                  </div>

                  <div className="row">
                    {!isNaN(formatNumber(pendientePago)) && (
                      <p className="ml-3">{`Pago pendiente  ${coopConfig.currency()?.symbol} ` + formatMoneyNumber(pendientePago)}</p>
                    )}
                    <div className="input-group" style={{ borderStyle: 'none', marginLeft: -5 }}>
                      <div className="col-xl-6 col-lg-12">
                        <FormInput
                          id="monto_pagado"
                          type="number"
                          name="monto_pagado"
                          label=""
                          placeholder={`Monto pagado (${coopConfig.currency()?.symbol})`}
                          error={errors.monto_pagado}
                          register={register}
                          step=".01"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: 10 }}>
                    <div className="input-group" style={{ borderStyle: 'none', marginLeft: -5 }}>
                      <div className="col-xl-6 col-lg-12">
                        <Controller
                          control={control}
                          name="id_metodo_pago"
                          render={({ field: { onChange, onBlur, value, ref, error } }) => (
                            <Select
                              isSearchable={false}
                              placeholder="Método de pago"
                              options={metodosPago}
                              onChange={onChange}
                              value={value}
                              styles={{
                                placeholder: defaultStyles => {
                                  return {
                                    ...defaultStyles,
                                    fontSize: 14,
                                  };
                                },
                                control: provided => ({
                                  ...provided,
                                  width: '100%',
                                }),
                              }}
                            />
                          )}
                        />
                        {errors?.id_metodo_pago && <div className="invalid-feedback">{errors?.id_metodo_pago.message}</div>}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6">
                      <button className="btn btn-sirio mt-3" type="submit">
                        Continuar
                      </button>
                    </div>
                  </div>
                </form>
              </CardContainer>
            )}
            {showFirma && <EntregasXProductorFirma data={dataForm} pagos={pagos} cuenta={cuenta} />}
          </div>
          <div className="col-xl-4 col-lg-5">
            <EntregasXProductorInfo uuid_cuenta={uuid_cuenta} id_cuenta={id_cuenta} id_acopio={id_acopio} entregas={entregas} />
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default EntregasXProductorPagoPendiente;
