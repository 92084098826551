import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import { Link, useParams } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import BlockUi from 'react-block-ui';
import env from './../../environment.js';
import './comites-detalle.css';
import Swal from 'sweetalert2';
import { useAuth } from '../../components/content/auth';

const ComitesRevisar = () => {
  const { uuid } = useParams();
  const [blocking, setBlocking] = useState(false);
  const [evaluacion, setEvaluacion] = useState({});
  const [firmaBlob, setFirmaBlob] = useState(null);
  const auth = useAuth();

  const data = [
    { value: 1, label: 'Transición 0' },
    { value: 2, label: 'Transición 1' },
    { value: 3, label: 'Transición 2' },
    { value: 4, label: 'Orgánico' },
    { value: 5, label: 'Transición 3' },
    { value: 6, label: 'Convencional' },
    { value: 7, label: 'Sin Asignar' },
    { value: 8, label: 'Activo' },
    { value: 9, label: 'Suspendido' },
  ];

  useEffect(() => {
    if (uuid != null) {
        setEvaluacion(JSON.parse(localStorage.getItem('formData')));
        const firma = localStorage.getItem('firma');

        if(firma) {
          const blob = base64ToBlob(firma, 'image/png');
          setFirmaBlob(blob);
          setEvaluacion(evaluacion => ({ ...evaluacion, firma_imagen: firma }));
        }
    }
  }, [uuid]);

  const base64ToBlob = (base64, mime) => {
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mime });
  };
  
  const onSubmit = () => {
    const formData = new FormData();
    for (let key in evaluacion) {
      if (evaluacion[key] instanceof Array) {
        formData.append(key, JSON.stringify(evaluacion[key]));
      } else {
        formData.append(key, evaluacion[key]);
      }
    }
    if (firmaBlob) {
      formData.append('firma_imagen', firmaBlob, 'firma.png');
    }

    setBlocking(true);

    Api.createMultiPart('comite_evaluaciones', formData)
      .then(response => {
        let data = response.data[0];
        setBlocking(false);
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Se registró con éxito la evaluación -> ${data.uuid}`,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem('formData');
            localStorage.removeItem('firma');
            setTimeout(() => {
              window.location.href = `/admin/cuentas/perfil/${uuid}`;
            }, 100); // 100ms delay
          }
        })
      })
      .catch(e => {
        setBlocking(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        });
      });
  };

  return (
    <>
      <div>
        <Breadcrumb>
        
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item active">Evaluación</li>
          </ol>

          <Link to={{ pathname: `/admin/cuentas/comite/${uuid}` }}>
            <button
              type="button"
              className="btn btn-outline-secondary mt-4"
              style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-arrow-left"></i> Volver a editar
            </button>
          </Link>
        </Breadcrumb>

        <div className="row pb-4">
          <div className="col-xl-12">
            <CardContainer height={'100%'}>
              <div className="row">
                <div className="col-xl-12">
                  <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
                    <div className="reporte-evaluacion">
                      <table className="table table-report table-no-border mb-0">
                        <tbody>
                          <tr>
                            <td>
                              {evaluacion.cooperativa_logo && (
                                <img
                                  style={{
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  src={`${env.assetURL}${evaluacion.cooperativa_logo}`}
                                  alt="logo_cooperativa"
                                />
                              )}
                            </td>
                            <td>
                              {evaluacion.certificacion_logo && (
                                <img
                                  style={{
                                    width: 'auto',
                                    height: '100px',
                                    float: 'right',
                                  }}
                                  src={`${env.assetURL}${evaluacion.certificacion_logo}`}
                                  alt="logo_ceritifcacion"
                                />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <h5 className="text-center py-4">
                        <b>FORMATO COMITÉ DE APROBACIÓN</b>
                      </h5>

                      <table className="table table-bordered table-report">
                        <tbody>
                          <tr>
                            <td rowSpan="2" className="text-middle">
                              <b>Nombre del Productor:</b> {evaluacion.nombre}
                            </td>
                            <td rowSpan="2" className="text-middle">
                              <b>Código:</b> {evaluacion.codigo_tx}
                            </td>
                            <td rowSpan="2" className="text-middle">
                              <b>Comunidad:</b> {evaluacion.localidad}
                            </td>
                            <td>
                              <b>Categoría:</b> {evaluacion?.cuenta_categoria?.label}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Ciclo:</b> {evaluacion.ciclo}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <b>Nivel de riesgo para este productor</b>
                            </td>
                            <td colSpan="2">{evaluacion.nivel_riesgo}</td>
                          </tr>
                        </tbody>
                      </table>

                      <p>
                        <b>
                          Basándose en el contenido de la ficha (s) técnica (s) de inspección interna, el comité interno
                          ha tomado la siguiente decisión:
                        </b>
                      </p>

                      <table className="table table-bordered">
                        {// eslint-disable-next-line eqeqeq
                        evaluacion.condicion == 1 && (
                          <tbody>
                            <tr>
                              <td className="w-25">
                                <b>a) Aprobado sin condiciones</b>
                              </td>
                              <td colSpan="2">
                                <b>Observación:</b> {evaluacion.aprob_sc_observaciones && evaluacion.aprob_sc_observaciones}
                              </td>
                            </tr>
                          </tbody>
                        )}
                        {// eslint-disable-next-line eqeqeq
                        evaluacion.condicion == 2 && (
                          <tbody>
                            <tr>
                              <td className="w-25" rowSpan={evaluacion.aprob_cc_list.length + 3}>
                                <b>b) Aprobado con condiciones (indicar las condiciones):</b>
                              </td>
                              <td rowSpan="2">
                                <b>Condiciones establecidas</b>
                              </td>
                              <td rowSpan="2">
                                <b>Plazo de cumplimiento</b>
                              </td>
                              <td colSpan="3">
                                <b>Verificación del cumplimiento:</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Cumplido</b>
                              </td>
                              <td>
                                <b>No Cumplido</b>
                              </td>
                              <td>
                                <b>Fecha</b>
                              </td>
                            </tr>
                            {evaluacion.aprob_cc_list.map((condition, i) => {
                              return (
                                <tr key={i}>
                                  <td>{condition.condicion}</td>
                                  <td>{condition.plazo}</td>
                                  <td>
                                    {// eslint-disable-next-line eqeqeq
                                    condition.verif_cumplido == 1 ? 'X' : ''}
                                  </td>
                                  <td>
                                    {// eslint-disable-next-line eqeqeq
                                    condition.verif_cumplido == 2 ? 'X' : ''}
                                  </td>
                                  <td>{condition.verif_fecha}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                        {// eslint-disable-next-line eqeqeq
                        evaluacion.condicion == 3 && (
                          <tbody>
                            <tr>
                              <td className="w-20">
                                <b>c)Sancionado</b>
                              </td>
                              <td className="w-20">
                                <b>Categoría en que estaba:</b> {data.find(item => item.value == evaluacion.sanc_cat_anterior)?.label}
                              </td>
                              <td className="w-20">
                                <b>Categoría en que se ubica por la sanción:</b> {data.find(item => item.value == evaluacion.sanc_cat_nueva)?.label}
                              </td>
                              <td className="w-20">
                                <b>Fecha de inicio de la sanción:</b> {evaluacion.sanc_fecha_inicio}
                              </td>
                              <td className="w-20">
                                <b>Fecha de término de la sanción:</b> {evaluacion.sanc_fecha_termino}
                              </td>
                            </tr>
                            <tr>
                              <td rowSpan="2">
                                <b>Causas de la sanción:</b> {evaluacion.sanc_observaciones}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4">{evaluacion.aprob_sc_observaciones}</td>
                            </tr>
                          </tbody>
                        )}
                      </table>

                      <p>
                        <b>Autorización por el comité interno de aprobación</b>
                      </p>

                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td className="text-center align-bottom">
                              <span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{evaluacion.lugar}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                              <br></br>
                              <span>
                                <ins>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{evaluacion.fecha_aut}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </ins>
                              </span>
                              <br></br>
                              <b>Lugar y fecha</b>
                            </td>
                            <td className="text-center align-bottom">
                              {evaluacion.firma_imagen && (
                                <img
                                  style={{
                                    width: '250px',
                                    height: 'auto',
                                  }}
                                  src={evaluacion.firma_imagen}
                                  alt="firma"
                                />
                              )}
                              <br></br>
                              <span>
                                <ins>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{auth.user.nombre}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </ins>
                              </span>
                              <br></br>
                              <b>Nombre y firma</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <button
                        onClick={() => onSubmit()}
                        type="button"
                        className="btn btn-sirio mt-4"
                        style={{ borderRadius: '20px 20px 20px 20px' }}>
                        <i className="fa fa-save"></i> Guardar evaluación
                      </button>

                    </div>
                  </BlockUi>
                </div>
              </div>
            </CardContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComitesRevisar;
