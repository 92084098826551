import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../../components/card/card-container.jsx';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb.jsx';
import Api from '../../../api/global.service';
import { useAuth } from '../../../components/content/auth.jsx';
import './entregas-x-productor.css';
import Select, { components } from 'react-select';
import env from '../../../environment.js';
import { useHistory } from 'react-router-dom';

const customStyles = {
  control: base => ({
    ...base,
    flexDirection: 'row-reverse',
    width: '100%',
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: '#999',
      fontSize: 14,
    };
  },
};

const EntregasXProductorSeleccion = () => {
  const auth = useAuth();
  const history = useHistory();
  
  const [inputText, setInputText] = useState('');
  const [cuentas, setCuentas] = useState([]);
  const [blocking, setBlocking] = useState(true);
  console.log(cuentas)

  const handleInputChange = (inputText, meta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      setInputText(inputText);
    }
  };

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className="fa fa-search" aria-hidden="true" style={{ position: 'initial' }}></i>
        </components.DropdownIndicator>
      )
    );
  };

  const handleChange = selected => {
    window.location.href = '/admin/acopio/entregas_por_productor/form/' + selected.value + '/' + selected.uuid_cuenta;
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setBlocking(true);
    Api.search('cuentas', {
      filter: [{ column: 'id_ciclo', operator: '=', value: localStorage.getItem(`${env.appName}-ciclo_id`) }],
    }).then(response => {
      let _items = response.data.map(function(item) {
        return { value: item['id'], label: item['nombre'], uuid_cuenta: item['uuid'] };
      });
      setCuentas(_items);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Entregas</li>
        </ol>
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-xl-6 col-lg-8">
            <p className="seleccion-titulo">{'Registro de nueva entrega'}</p>
            <div className="seleccion-subtitulo">{'Selecciona un productor'}</div>

            <div className="row mt-3">
              <div className="col-xl-8 col-lg-10">
                <Select
                  components={{ DropdownIndicator }}
                  styles={customStyles}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Nombre del productor"
                  noOptionsMessage={() => 'No hay elementos por mostrar'}
                  options={cuentas}
                  onChange={handleChange}
                />
              </div>

              <div className="col-xl-4 col-lg-2">
                {auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_NUEVO') && (
                  <button
                    onClick={() => history.push(`/admin/cuentas/agregar`, { redirect: '/admin/acopio/entregas_por_productor/seleccion' })}
                    type="button"
                    className="btn btn-outline-secondary"
                    style={{ borderRadius: '20px 20px 20px 20px', marginLeft: 10 }}>
                    <i className="fa fa-plus"></i> Nuevo productor
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default EntregasXProductorSeleccion;
