import React from 'react';
import { useHistory } from 'react-router-dom';

const BackButton = ({ label, goBackDefault = '/dashboard' }) => {
  const history = useHistory();

  const goBackOrDefault = () => {
    const doesAnyHistoryEntryExist = history.location.key;

    if (doesAnyHistoryEntryExist) {
      history.goBack();
    } else {
      // no entry exists go to dashboard
      history.push(goBackDefault);
    }
  };
  return (
    <button onClick={goBackOrDefault} type="button" className="btn btn-outline-secondary btn-sirio" style={{ borderRadius: '20px' }}>
      <i className="fa fa-arrow-left mr-2"></i> {label ? label : 'Volver'}
    </button>
  );
};

export default BackButton;
