import React from 'react';
import env from '../../environment';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrowIcon.svg';

const TerrainDirection = ({ angle, color = 'black' }) => (
  <div
    style={{
      height: '70px',
      width: '70px',
      position: 'absolute',
      left: '12px',
      bottom: '12px',
      padding: 0,
      zIndex: 1000,
    }}>
    <ArrowIcon
      fill={color}
      style={{
        height: '30px',
        position: 'absolute',
        top: '10px',
        left: '18px',
        zIndex: 1000,
        rotate: `${angle}deg`,
      }}
      alt="North"
    />
    <p
      style={{
        textAlign: 'center',
        position: 'absolute',
        bottom: -4,
        left: 0,
        fontSize: 8,
        width: '100%',
        color: color,
      }}>
      Desnivel
    </p>
  </div>
);

export default TerrainDirection;
