import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';

import Api from '../../api/global.service.js';

import CardContainer from '../../components/card/card-container';
import { ReactComponent as RemoveCircleIcon } from '../../assets/icons/remove_circle_outline_black.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add_icon.svg';

const inputArray = [
  {
    name: 'nombre',
    placeholder: 'Nombre de Parcela',
    rules: {
      validate: {
        isRequired: (v) => !!v || 'Se requiere un nombre para la parcela',
      },
    },
  },
  {
    name: 'superficie',
    placeholder: 'Superficie (ha) ',
    rules: {
      validate: {
        isRequired: (v) => !!v || 'Superficie es requerida',
        isNumber: (v) => !isNaN(v) || 'Superficie debe ser un número',
        hasMaxThreeDecimals: (v) => {
          const regex = /^\d+(\.\d{1,3})?$/;
          return regex.test(v) || 'Superficie debe tener máximo tres decimales';
        },
      },
    },
  },
  {
    name: 'plantas',
    placeholder: 'Número de plantas',
    rules: {
      validate: {
        isRequired: (v) => !!v || 'Número de plantas es requerido',
        isNumber: (v) => !isNaN(v) || 'Número de plantas debe ser un número',
        isInteger: (v) =>
          Number.isInteger(parseFloat(v)) ||
          'Número de plantas no puede tener decimales',
      },
    },
  },
];

const CrearParcelas = () => {
  const history = useHistory();
  const [blocking, setBlocking] = useState(true);
  const redirect = history.location.state?.redirect;
  const { uuid } = useParams();

  const {
    control,
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      parcelas: [{ nombre: '', superficie: '', plantas: '' }],
    },
  });

  const onSubmit = (values) => {
    setBlocking(true);
    const { parcelas } = values;

    if (!parcelas || parcelas.length === 0) return console.log('No hay datos');

    Promise.allSettled(
      parcelas.map((value) => {
        value.uuid_cuenta = uuid;
        Api.create('admin/acopios/crear_parcela', value);
      })
    )
      .then(() => {
        setBlocking(false);
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Se registró con éxito la(s) parcela(s)`,
          allowOutsideClick: false,
        }).then(res => {
          if (res.isConfirmed) {
            if (redirect) {
              history.push(redirect);
            } else {
              history.push('/admin/cuentas/list');
            }
          }
        });
      })
      .catch((e) => {
        setBlocking(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e,
        });
      });
  };

  const onCancel = () => {
    history.push('/admin/cuentas/list');
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parcelas',
    mode: 'all',
  });

  return (
    <CardContainer height={'100%'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mt-3">
          <div className="col-xl-4">
            <h3 className=" active">Información de parcela</h3>
          </div>
        </div>

        {fields.map((item, index) => (
          <div key={item.id}>
            <div className="row mt-3">
              <div
                className="col-xl-4 pt-2"
                style={{
                  backgroundColor: '#F7EFE7',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3 className="breadcrumb-item active">Parcela {index + 1}</h3>
                {index > 0 && (
                  <button
                    className="btn-reset"
                    type="reset"
                    onClick={() => remove(index)}
                  >
                    <RemoveCircleIcon />
                  </button>
                )}
              </div>
            </div>

            {inputArray.map((input) => (
              <div className="row mt-3" key={input.name}>
                <div className="col-xl-4">
                  <div className="input-group">
                    <input
                      className='form-control'
                      control={control}
                      error={errors.parcelas?.[index]?.[input.name]}
                      name={`parcelas.${index}.${input.name}`}
                      id={`parcelas.${index}.${input.name}`}
                      placeholder={input.placeholder}
                      {...register(`parcelas.${index}.${input.name}`, input.rules
                    )}
                    />
                  </div>
                  {errors.parcelas?.[index]?.[input.name] && (
                    <div className="invalid-feedback">
                      {errors.parcelas?.[index]?.[input.name].message}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}

        <div className="row mt-3">
          <div className="col-xl-4">
            <button
              className="btn mt-3 border success green"
              type="button"
              onClick={() => {
                append({
                  nombre: '',
                  superficie: '',
                  plantas: '',
                });
                trigger('fields');
              }}
            >
              <AddIcon style={{ color: 'green', marginRight: '5px' }} />
              Añadir parcela
            </button>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-xl-3 justify-content-around btn-group">
            <button className="btn btn-sirio mt-3" type="submit">
              Guardar
            </button>

            <button
              className="btn mt-3 float-right btn-light"
              type="button"
              onClick={() => onCancel()}
            >
              Cancelar
            </button>
          </div>
        </div>
      </form>
    </CardContainer>
  );
};

export default CrearParcelas;
