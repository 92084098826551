import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useReactToPrint } from 'react-to-print';
import Api from '../../api/global.service';
import { useParams, useHistory } from 'react-router-dom';
import CardBasic from '../../components/card/card-basic.jsx';
import BlockUi from 'react-block-ui';
import GoogleMapReact from 'google-map-react';
import env from './../../environment.js';
import CardColindante from '../../components/card/card-colindante';
import MapText from '../../components/card/map-text';
import TerrainDirection from '../../components/map/terrain-direction';
import NorthIcon from '../../components/map/north-icon.jsx';
import parsePoint from '../../utils/parsePoint';
import BackButton from '../../components/link/back-button.jsx';
import { useCoopConfig } from '../../components/content/coop-config.jsx';
import FormSelectMaterial from '../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import useCroquisHook from '../../hooks/useCroquisHook.jsx';
import useGetColindancias from '../../hooks/useGetColindancias.jsx';
import MarkerInfoWindow from '../../components/map/marker-infowindow.jsx';

// Estilos adicionales para mejorar impresión
const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media print {
    .pagebreak {
      clear: both;
      page-break-after: always !important;
    }

    tr  {
      border: 2px solid #000 !important;
      padding: 5px 10px !important;
    }

    td, th  {
      font-size: 10px !important;
      padding: 5px 10px !important;
    }

  }
`;

const ParcelaProfilePrint = () => {
  const componentRef = useRef(null);
  const { uuid } = useParams();
  const { mostrarAreaCalculada } = useCoopConfig();
  const history = useHistory();
  const [parcela, setParcela] = useState({});
  const [parcelaData, setParcelaData] = useState({});
  const [centroid, setCentroid] = useState(null);
  const [blocking, setBlocking] = useState(false);
  const { areaGMaps, renderMap, center, angle, pointsOnMap, lengths, zoom } = useCroquisHook(parcelaData);
  const [nameToPrint, setNameToPrint] = useState('CroquisParcela');
  const [mapType, setMapType] = useState('terrain');
  const { colindancias } = useGetColindancias({ uuid, setBlocking });

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: nameToPrint,
    onAfterPrint: useCallback(() => history.goBack(), [history]),
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: pageStyle,
  });

  useEffect(() => {
    if (uuid != null) {
      setBlocking(true);

      Api.get('parcelas_croquis', uuid)
        .then(response => {
          setParcela(response.data[0]);
          const pol = response.data.find(p => typeof p.poligono === 'string')?.poligono;
          let cent;
          if (response.data[0] && !isNaN(response.data[0]?.centroide_lat) && !isNaN(response?.data[0]?.centroide_lat)) {
            cent = { lat: parseFloat(response?.data[0]?.centroide_lat), lng: parseFloat(response?.data[0]?.centroide_lng) };
            setCentroid(cent);
          }
          setParcelaData({
            parcelaPolygon: pol,
            centroid: cent,
          });
          setNameToPrint(`CroquisParcela_${response.data[0].nombre}_${response.data[0].cuenta}`);
          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('error:', e);
          setBlocking(false);
        });
    }
  }, [uuid]);

  return (
    <div>
      <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
        <BackButton label="Volver" goBackDefault="/admin/parcelas/list" />
        <button
          onClick={handlePrint}
          className="btn btn-outline-secondary ml-2"
          style={{ borderRadius: '20px 20px 20px 20px' }}
          type="button">
          <i className="fa fa-print" aria-hidden="true"></i> Imprimir
        </button>
        <div className="row pt-4 pl-4">
          <div className="col-8">
            <h5>
              <span className="font-weight-bold">NOTA:</span> Para mejores resultados al imprimir te sugerimos ajustar el zoom y la posición
              del mapa.
            </h5>
          </div>
          <div className="col-4">
            <FormSelectMaterial
              id="tipoMapa"
              error={false}
              label="Tipo Mapa"
              value={mapType}
              onChange={v => setMapType(v)}
              height={36}
              options={[
                { value: 'satellite', label: 'Satelital' },
                { value: 'terrain', label: 'Terreno' },
              ]}
              showAll={false}
              // showAllLabel="Todas"
            />
          </div>
        </div>

        <div ref={componentRef}>
          <style type="text/css" media="print">
            {'\
						@page { size: landscape; }\
						'}
          </style>
          <div className="row justify-content-end mr-3">
            <img
              style={{
                width: 'auto',
                height: '80px',
                marginRight: '10px',
              }}
              src={`${env.assetURL}${parcela.logo}`}
              alt="logo_cooperativa"
            />
            <img
              style={{
                width: 'auto',
                height: '80px',
              }}
              src={`${env.assetURL}${parcela.certificacion_logo}`}
              alt="logo_ceritifcacion"
            />
          </div>
          <h2 className="page-header center" style={{ textAlign: 'center' }}>
            CROQUIS POR PARCELA
          </h2>
          <table className="table table-striped" type="text/css">
            <tbody>
              <tr>
                <td className="font-weight-bold">Nombre del Productor:</td>
                <td>{parcela.cuenta}</td>
                <td className="font-weight-bold">Nombre de la parcela:</td>
                <td>{parcela.nombre}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Municipio:</td>
                <td>{parcela.municipio}</td>
                <td className="font-weight-bold">Localidad:</td>
                <td>{parcela.localidad}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Coordenadas Geográficas:</td>
                <td colSpan="3">
                  {' '}
                  {/* TODO: Lat/Long are inverted in backend. Fix when sql fix and all logic is updated */}
                  Lat: {parsePoint(parcela?.centroide_lng)} / Lon: {parsePoint(parcela?.centroide_lat)}
                </td>
              </tr>
              <tr>
                {mostrarAreaCalculada() && (
                  <>
                    <td className="font-weight-bold">Area (hectáreas calculadas):</td>
                    <td>{areaGMaps}</td>
                  </>
                )}
                <td className="font-weight-bold">Area (hectáreas reportadas por el productor):</td>
                <td>{parcela.superficie}</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <CardColindante title="Norte" data={colindancias.norte} />
            </div>
            <div className="col-4"></div>
          </div>
          <div className="row">
            <div className="col-3 align-self-sm-center">
              <CardColindante title="Oeste" data={colindancias.oeste} vertical />
            </div>
            <div className="col-6">
              <div className="map-rounded" style={{ height: '300px', width: '100%' }}>
                <NorthIcon color={mapType === 'satellite' ? 'white' : 'black'} />
                {parcela.poligono ? (
                  <>
                    <TerrainDirection angle={angle} color={mapType === 'satellite' ? 'white' : 'black'} />
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: env.googleApiKey,
                        libraries: ['geometry', 'elevation'],
                      }}
                      options={() => ({ mapTypeId: mapType, rotateControl: true })}
                      center={center}
                      zoom={zoom}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) => renderMap(map, maps)}>
                      {lengths.map((it, i) => {
                        return (
                          <MapText
                            lat={it?.lat}
                            lng={it?.lng}
                            key={i}
                            text={it?.length + 'm'}
                            style={{ color: mapType === 'satellite' ? 'white' : 'black' }}
                          />
                        );
                      })}
                      {pointsOnMap.map((it, i) => (
                        <MapText
                          lat={it?.lat}
                          lng={it?.lng}
                          key={i}
                          text={it?.title}
                          style={{ color: mapType === 'satellite' ? 'white' : 'black' }}
                        />
                      ))}
                    </GoogleMapReact>
                  </>
                ) : null}
                {parcela.poligono === null &&
                  parcelaData?.centroid &&
                  !isNaN(parcelaData?.centroid.lat) &&
                  !isNaN(parcelaData?.centroid.lng) && (
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: env.googleApiKey }}
                      options={() => ({ mapTypeId: mapType })}
                      center={centroid}
                      zoom={zoom}>
                      <MarkerInfoWindow
                        lat={parcelaData?.centroid?.centroide_lat}
                        lng={parcelaData?.centroid?.centroide_lng}
                        productor={'Polígono pendiente de registrar'}
                        color={'#91B833'}
                      />
                    </GoogleMapReact>
                  )}
                {parcelaData?.centroid === null && parcela.poligono === null && (
                  <CardBasic
                    height={290}
                    label="Ubicación no disponible"
                    value={''}
                    style={{ backgroundColor: env.colors.neutral_1 }}></CardBasic>
                )}
              </div>
            </div>
            <div className="col-3 align-self-sm-center">
              <CardColindante title="Este" data={colindancias.este} vertical />
            </div>
          </div>
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4 mt-3">
              <CardColindante title="Sur" data={colindancias.sur} />
            </div>
            <div className="col-4"></div>
          </div>

          <div className="pagebreak"></div>

          <div className="row">
            <div className="col-4">
              <table className="table table-striped" type="text/css">
                <tbody>
                  <tr>
                    <td className="text-center font-weight-bold" colSpan="4">
                      Coordenadas del croquis
                    </td>
                  </tr>
                  <tr>
                    <td>Punto</td>
                    <td>Latitud</td>
                    <td>Longitud</td>
                    <td>Altitud</td>
                  </tr>
                  {pointsOnMap.map((p, i) => {
                    return (
                      <tr key={i}>
                        <td>{p.title}</td>
                        <td>{parsePoint(p.lat)}</td>
                        <td>{parsePoint(p.lng)}</td>
                        <td>{p.elevation}m</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

export default ParcelaProfilePrint;
