/* eslint-disable react/display-name */
import React, { useState, forwardRef, useImperativeHandle, useEffect, useCallback } from 'react';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import * as yup from 'yup';
import { publish } from '../events';
import { useCoopConfig } from '../content/coop-config.jsx';

const ModalPrecioRemanente = forwardRef((props, ref) => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [errors, setErrors] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [values, setValues] = useState({});
  const [organico, setOrganico] = useState('Orgánico ($/kg)');
  const [transicion0, setTransicion0] = useState('Transición 0 ($/kg)');
  const [transicion1, setTransicion1] = useState('Transición 1 ($/kg)');
  const [transicion2, setTransicion2] = useState('Transición 2 ($/kg)');
  const [transicion3, setTransicion3] = useState('Transición 3 ($/kg)');
  const yupInput = yup
    .number()
    .required('Debe escribir una cantidad')
    .min(0, 'Ingrese un número mayor que cero 0')
    .transform(value => (isNaN(value) ? undefined : value));
  const schema = yup.object().shape({
    organico: yupInput,
    transicion0: yupInput,
    transicion1: yupInput,
    transicion2: yupInput,
    transicion3: yupInput,
  });

  useEffect(() => {
    getCategorias();
  }, []);

  const getCategorias = async () => {
    let response = await Api.Acopio.get('remanente/categorias');
    setCategorias(response.data.sort((a, b) => { if (a.id > b.id) return -1; }));
  };

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
  }));

  const showHandler = valuesData => {
    setShow(true);
    setErrors(null);
    setValues(valuesData);
    defaultValues(valuesData);
  };

  const defaultValues = valuesData => {
    if (valuesData?.precioCategoria) {
      valuesData.precioCategoria.forEach(pc => {
        setCantidad(pc.id_cat_cuenta_categoria, parseFloat(pc.precio));
      });
    }
  };

  const closeHandler = () => {
    setShow(false);
  };

  const updatedValues = (dataVal, precioVal) => {
    let categoriasFilter = categorias;
    if (precioVal) {
      // there has been an update?
      categoriasFilter = categoriasFilter.filter(
        c =>
          dataVal.find(d => d.id == c.id)?.value &&
          dataVal.find(d => d.id == c.id)?.value != parseFloat(precioVal.find(p => p.id_cat_cuenta_categoria == c.id)?.precio)
      );
    }
    return categoriasFilter;
  };

  const validateHandler = () => {
    let dataVal = {
      organico: categorias.find(c => c.id == 4) ? organico : 0,
      transicion0: categorias.find(c => c.id == 1) ? transicion0 : 0,
      transicion1: categorias.find(c => c.id == 2) ? transicion1 : 0,
      transicion2: categorias.find(c => c.id == 3) ? transicion2 : 0,
      transicion3: categorias.find(c => c.id == 5) ? transicion3 : 0,
    };
    schema
      .validate(dataVal, { abortEarly: false })
      .then(function(valid) {
        // dataVal as Object
        const precioStored = values?.precioCategoria;
        dataVal = [
          { id: 4, value: dataVal.organico },
          { id: 1, value: dataVal.transicion0 },
          { id: 2, value: dataVal.transicion1 },
          { id: 3, value: dataVal.transicion2 },
          { id: 5, value: dataVal.transicion3 },
        ];

        const categoriasFilter = updatedValues(dataVal, precioStored);

        // data post
        const dataCoop = { id_cooperativa: auth.user.id_cooperativa, id_ciclo: values.id_ciclo };
        let data = [];
        categoriasFilter.forEach(c => {
          const dataCategoria = { categoria: c.nombre, id_cat_cuenta_categoria: c.id };
          const dataPrecio = precioStored ? { precio_id: precioStored.find(pc => pc.id_cat_cuenta_categoria == c.id)?.precio_id } : {};
          const precio = dataVal.find(d => d.id == c.id)?.value;
          precio && data.push({ ...dataCoop, ...dataCategoria, ...dataPrecio, precio_por_kg: parseFloat(precio * 100) });
        });

        if (data.length) {
          setBlocking(true);
          // Post request
          let registroPrecio = [];
          data.forEach(d => {
            precioStored
              ? registroPrecio.push(Api.Acopio.update('remanente/registro_precio', d.precio_id, d))
              : registroPrecio.push(Api.Acopio.create('remanente/registro_precio', d));
          });
          Promise.all(registroPrecio).then(values => {
            setBlocking(false);
            setShow(false);
            publish('Precio::onSave', { dataRes: values, dataCoop: dataCoop });
          });
        }
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
      });
  };

  const setCantidad = (inputName, inputVal) => {
    if (inputName == 'organico' || inputName == 4) setOrganico(inputVal);
    else if (inputName == 'transicion0' || inputName == 1) setTransicion0(inputVal);
    else if (inputName == 'transicion1' || inputName == 2) setTransicion1(inputVal);
    else if (inputName == 'transicion2' || inputName == 3) setTransicion2(inputVal);
    else if (inputName == 'transicion3' || inputName == 5) setTransicion3(inputVal);
  };

  const InputPrice = useCallback(({ label, inputName, inputVal, error }) => {
    return (
      <>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <i className="fa fa-search" style={{ padding: 14, color: '#ffffff' }}></i>
          </div>
          <input
            type="number"
            name={inputName}
            className="form-control"
            placeholder={label}
            value={inputVal}
            onChange={event => setCantidad(inputName, event.target.value)}
          />
          <div className="input-group-append">
            <i className="fa fa-chevron-down" style={{ padding: 14, color: '#ffffff' }}></i>
            <span className="p-5 pl-0">{inputVal > 0 && label}</span>
          </div>
        </div>
        {error && <div className="invalid-feedback input-group border-0 mb-2">{error}</div>}
      </>
    );
  }, []);

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div className="modal-dialog" style={{ top: 40, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 100px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit' }}>
            <h4 className="modal-title">{props.title}</h4>
            <button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row my-4">
              <div className="col-12">
                {categorias.length > 0 && (
                  <>
                    {categorias?.map(c => (
                      <>
                        {c.id == 4 && (
                          <InputPrice
                            label={c.nombre + ` (${coopConfig.currency()?.symbol}/${coopConfig.acopioUnits()?.abreviacion})`}
                            inputName="organico"
                            inputVal={organico}
                            error={errors?.organico}
                          />
                        )}
                        {c.id == 1 && (
                          <InputPrice
                            label={c.nombre + ` (${coopConfig.currency()?.symbol}/${coopConfig.acopioUnits()?.abreviacion})`}
                            inputName="transicion0"
                            inputVal={transicion0}
                            error={errors?.transicion0}
                          />
                        )}
                        {c.id == 2 && (
                          <InputPrice
                            label={c.nombre + ` (${coopConfig.currency()?.symbol}/${coopConfig.acopioUnits()?.abreviacion})`}
                            inputName="transicion1"
                            inputVal={transicion1}
                            error={errors?.transicion1}
                          />
                        )}
                        {c.id == 3 && (
                          <InputPrice
                            label={c.nombre + ` (${coopConfig.currency()?.symbol}/${coopConfig.acopioUnits()?.abreviacion})`}
                            inputName="transicion2"
                            inputVal={transicion2}
                            error={errors?.transicion2}
                          />
                        )}
                        {c.id == 5 && (
                          <InputPrice
                            label={c.nombre + ` (${coopConfig.currency()?.symbol}/${coopConfig.acopioUnits()?.abreviacion})`}
                            inputName="transicion3"
                            inputVal={transicion3}
                            error={errors?.transicion3}
                          />
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
            {!blocking && (
              <>
                <button
                  onClick={() => closeHandler()}
                  type="button"
                  className="btn btn-outline-secondary btn-sirio"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
                <button
                  onClick={() => validateHandler()}
                  type="button"
                  className="btn btn-outline-secondary btn-sirio"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-save"></i> Guardar
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalPrecioRemanente;
