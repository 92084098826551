import React, { useState, useEffect } from 'react';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import Api from '../../api/global.service';
import MarkerInfoWindowParcela from '../../components/map/marker-infowindow-parcela.jsx';
import BlockUi from 'react-block-ui';
import GoogleMapReact from 'google-map-react';
import env from '../../environment';
import SelectCiclo from '../../components/select/select-ciclo.jsx';

import ParcelasResumenPlantas from '../../components/dashboards/parcelas/parcelas-resumen-plantas';
import ParcelasResumenOtrasActividades from '../../components/dashboards/parcelas/parcelas-resumen-otras-actividades';
import ParcelasResumenTotales from '../../components/dashboards/parcelas/parcelas-resumen-totales';
import ParcelasResumenTotalesHas from '../../components/dashboards/parcelas/parcelas-resumen-totales-has';
import './parcelas-resume.css';
import ParcelasResumenGenero from '../../components/dashboards/parcelas/parcelas-resumen-genero.js';
import descargaKML from '../../utils/downloadKML.js';

const ParcelasResume = () => {
  const [blockingMap, setBlockingMap] = useState(false);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [polygons, setPolygons] = useState([]);
  const [dataForKML, setDataForKML] = useState([]);

  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);
  const [idCiclo, setIdCiclo] = useState(0);
  const center = {
    lat: 17,
    lng: -92,
  };
  const zoom = 6;

  useEffect(() => {
    // Clear all previous polygons in map
    polygons.map(p => p.setMap(null));
    const tempPolygons = [];
    // Add new polygons in map
    markers.forEach(function(marker) {
      if (marker.poligono !== null) {
        let poligono = JSON.parse(marker.poligono);
        let poligono_data = poligono.coordinates[0].map(function(p, index) {
          return { lat: p[1], lng: p[0] };
        });
        var polygon = new maps.Polygon({
          paths: poligono_data,
          strokeColor: '#ffffff', //marker.cuenta_categoria_color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#ffffff', //marker.cuenta_categoria_color,
          fillOpacity: 0.35,
        });
        polygon.setMap(map);
        tempPolygons.push(polygon);
      }
    });
    setPolygons(tempPolygons);
  }, [map, maps, markers]);

  const renderMap = (map, maps) => {
    setMap(map);
    setMaps(maps);
  };

  useEffect(() => {
    Api.search('parcelas_kml', {
      filter: [{ column: 'id_ciclo', operator: '=', value: localStorage.getItem(`${env.appName}-ciclo_id`) }],
    })
      .then(response => {
        setDataForKML(response.data);
      })
      .catch(e => {
        console.log('e>>>', e);
      });
  }, [idCiclo]);

  const setFilter = value => {
    setBlockingMap(true);
    setIdCiclo(value.target.id);

    Api.search('parcelas', {
      filter: [
        { column: 'centroide_lat', operator: '!=', value: 'null' },
        { column: 'centroide_lng', operator: '!=', value: 'null' },
        { column: 'id_ciclo', operator: '=', value: value.target.id },
      ],
    })
      .then(response => {
        setMarkers(response.data);
        setBlockingMap(false);
      })
      .catch(e => {
        setBlockingMap(false);
      });
  };

  const onChildClick = (key, childProps) => {
    if (selectedElement && selectedElement.id === childProps.id) {
      onChildUNClick();
    } else {
      setSelectedElement(childProps);
      setActiveMarker(childProps.id);
      setInfoWindowFlag(true);
    }
  };
  const onChildUNClick = () => {
    setSelectedElement(null);
    setActiveMarker(null);
    setInfoWindowFlag(false);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item active">Parcelas</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCiclo>
      </Breadcrumb>

      {dataForKML.length > 0 && (
        <div className="row ">
          <div className="col align-self-end"></div>
          <div className="float-xl-right">
            <p>
              <img src={env.images.icon.kml} />
              <a
                onClick={() => descargaKML(dataForKML)}
                style={{
                  padding: 10,
                  color: '#789D16',
                  cursor: 'pointer',
                }}>
                {'Descargar Mapa KML'}
              </a>
            </p>
          </div>
        </div>
      )}
      {/* boxes dashboard */}
      <div className="row text-center">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-2 col-md-2 pr-4">
              <ParcelasResumenTotales id_ciclo={idCiclo} />
            </div>

            <div className="col-xl-4 col-md-4 pr-4">
              <ParcelasResumenPlantas id_ciclo={idCiclo} />
            </div>
            <div className="col-xl-4 col-md-4 pr-4">
              <ParcelasResumenOtrasActividades id_ciclo={idCiclo} />
            </div>

            <div className="col-xl-2 col-md-2">
              <ParcelasResumenTotalesHas id_ciclo={idCiclo} />
            </div>
          </div>
        </div>
      </div>

      {/* chart dashboard */}
      <div className="row text-center mt-2 mb-4">
        <div className="col-md-12">
          <ParcelasResumenGenero id_ciclo={idCiclo} />
        </div>
      </div>
      {/* map */}
      <div className="row">
        <div className="col-xl-12">
          <BlockUi tag="div" message="" blocking={blockingMap} renderChildren={true}>
            <Panel>
              <PanelBody className="p-0">
                <div
                  className="row"
                  style={{
                    height: 520,
                    backgroundColor: 'white',
                    borderRadius: '20px 20px 20px 20px',
                    padding: '20px 10px',
                  }}>
                  <div className="col-xl-12 col-md-12">
                    <div style={{ height: '100%', width: '100%' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: env.googleApiKey }}
                        options={map => ({
                          mapTypeId: map.MapTypeId.SATELLITE,
                        })}
                        center={center}
                        zoom={zoom}
                        onChildClick={onChildClick}
                        onClick={onChildUNClick}
                        onGoogleApiLoaded={({ map, maps }) => renderMap(map, maps)}>
                        {markers &&
                          markers.map((element, i) => {
                            // if (i === 0) console.log('element', element);
                            return (
                              <MarkerInfoWindowParcela
                                key={i}
                                lat={element.centroide_lat}
                                lng={element.centroide_lng}
                                color={element.cuenta_categoria_color}
                                productor={element.cuenta}
                                parcela={element.parcela_nombre}
                                superficie={element.parcela_superficie}
                                id={element.id_parcela}
                                selectedElement={selectedElement}
                                showInfoWindow={showInfoWindow}
                                activeMarker={activeMarker}
                                link={element.parcela_uuid ? env.pathEudr + '/' + element.parcela_uuid : null}
                                linkProductor={element.productor_uuid ? '/admin/cuentas/perfil/' + element.productor_uuid : null}
                              />
                            );
                          })}
                      </GoogleMapReact>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default ParcelasResume;
