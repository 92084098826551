import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import BlockUi from 'react-block-ui';

const ParcelasAlertas = ({ uuid, hasPolygon }) => {
  const [alertas, setAlertas] = useState([]);
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    if (uuid != null) {
      setBlocking(true);

      Api.get('parcelas_alertas', uuid)
        .then(response => {
          setAlertas(response.data);
          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error('error', e);
          setBlocking(false);
        });
    }
  }, []);

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
      {alertas.length === 0 && hasPolygon && (
        <div
          style={{
            backgroundColor: '#F6F7F5',
            padding: 20,
            borderRadius: 10,
            textAlign: 'center',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
          }}>
          <p style={{ marginBottom: 0 }}>
            <i
              className="fa fa-check"
              style={{
                color: '#789D16',
                marginRight: 10,
              }}
            />
            No se detectaron alertas
          </p>
        </div>
      )}
      {alertas.length > 0 &&
        hasPolygon &&
        alertas.map((alerta, index) => {
          const isError = Number(alerta.error) === 1;
          return (
            <div
              key={index}
              style={{
                backgroundColor: isError ? '#FFEED9' : '#F6F7F5',
                padding: isError ? '8px 0 15px 12px' : 20,
                borderRadius: 10,
                textAlign: 'center',

                display: 'flex',
                alignItems: 'baseline',
                marginBottom: 10,
              }}>
              {isError ? (
                <div
                  style={{
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                  }}>
                  <i
                    className="fa fa-exclamation-triangle"
                    ariaHidden="true"
                    style={{
                      color: '#E49D2A',
                      marginRight: 10,
                    }}
                  />
                </div>
              ) : null}

              <div>
                <p style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 5 }}>{alerta.titulo}</p>
                <p style={{ marginBottom: 0, textAlign: 'left' }}>{alerta.descripcion}</p>
                <p style={{ marginBottom: 0, textAlign: 'left' }}>{alerta.recomendacion}</p>
              </div>
            </div>
          );
        })}
    </BlockUi>
  );
};

export default ParcelasAlertas;
