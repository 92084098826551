import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Api from '../../api/global.service';
import * as yup from 'yup';
import Moment from 'moment';
import { publish } from '../../components/events.js';
import validacionNumero2Decimales, {
  validacionNumero2DecimalesNotRequired,
} from '../../nuup/utils/validaciones/validacionNumero2Decimales.js';
import formatNumber from '../../utils/formatNumber.js';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { useCoopConfig } from '../content/coop-config.jsx';

const ModalCreditoProductorAbono = forwardRef((props, ref) => {
  const customStyles = {
    control: base => ({
      ...base,
      flexDirection: 'row-reverse',
      width: '100%',
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        fontSize: 18,
        fontWeight: 400,
      };
    },
  };

  const coopConfig = useCoopConfig();
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [fecha, setFecha] = useState(null);
  const [data, setData] = useState({});
  const [cantidad, setCantidad] = useState('');
  const [idAnticipo, setIdAnticipo] = useState(0);
  const [idCiclo, setIdCiclo] = useState(0);
  const [comisionEfectivo, setComisionEfectivo] = useState('');

  const [errors, setErrors] = useState(null);
  const schema = yup.object().shape({
    fecha: yup
      .date()
      .max(moment().add(12, 'M'), 'Seleccione otra fecha')
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
      .required('Debe seleccionar una fecha'),
    cantidad: validacionNumero2Decimales(0.01, { required: 'Debe escribir un monto' }).max(
      data?.total_saldo,
      'El monto no debe exceder el saldo total'
    ),
    comision_efectivo: validacionNumero2DecimalesNotRequired(0),
  });

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
    setData: data => setData(data),
    setIdAnticipo: idAnticipo => setIdAnticipo(idAnticipo),
    setIdCiclo: idCiclo => setIdCiclo(idCiclo),
  }));

  useEffect(() => {}, []);

  const showHandler = () => {
    setShow(true);
    setFecha('');
    setCantidad('');
    setComisionEfectivo('');
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const validateHandler = () => {
    var data = {
      fecha: fecha,
      cantidad: cantidad,
      comision_efectivo: comisionEfectivo,
    };
    var that = this;
    schema
      .validate(data, { abortEarly: false })
      .then(function(valid) {
        //Datos validos
        const data = {
          uuid: uuidv4(),
          id_anticipo: idAnticipo,
          fecha_ejecucion: Moment(fecha).format('yyyy-MM-DD'),
          cantidad_pago: cantidad * 100,
          comision_efectivo: comisionEfectivo * 100,
        };

        Api.Acopio.create('anticipos_pagos', data)
          .then(response => {
            if (response.data.success) {
              setBlocking(false);
              setShow(false);
              publish('AnticipoPago::onSave', { ...response.data.data, id_ciclo: idCiclo });
            } else {
              let err = response.data.data;
              setErrors(err);
            }
          })
          .catch(e => {
            setBlocking(false);
          });
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
      });
  };

  const handleChange = selected => {
    console.log(selected);
  };

  const getTasaInteres = valor => {
    let tasa_interes = 0;
    if (valor) {
      tasa_interes = valor;
      if (valor > 0) {
        tasa_interes = valor / 100;
      }
    }

    return tasa_interes;
  };

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div className="modal-dialog" style={{ top: 0, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 50px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit' }}>
            <h4 className="modal-title">{props.title}</h4>
            <button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body" style={{ overflowY: 'auto' }}>
            <div className="pl-4">
              <div className="row">
                <div className="col-lg-12">
                  <h5>{data?.productor_nombre}</h5>
                </div>
              </div>
            </div>

            <div className="pl-4 pt-3">
              <div className="row">
                <div className="col-xl-8 col-lg-12 info-entregas-titulo">{'Importe'}</div>
                <div className="col-xl-4 col-lg-5 info-entregas-variable">
                  {coopConfig.currency()?.symbol + ' ' + formatNumber(data?.total || 0)}
                </div>
              </div>

              <div className="row">
                <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Capital desembolsado'}</div>
                <div className="col-xl-4 col-lg-5 info-entregas-variable">
                  {coopConfig.currency()?.symbol + ' ' + formatNumber(data?.total_anticipos || 0)}
                </div>
              </div>

              <div className="row">
                <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Interés acumulado'}</div>
                <div className="col-xl-4 col-lg-5 info-entregas-variable">
                  {coopConfig.currency()?.symbol + ' ' + formatNumber(data?.total_interes || 0)}
                </div>
              </div>

              <div className="row">
                <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Tasa de Interés'}</div>
                <div className="col-xl-4 col-lg-5 info-entregas-variable">{formatNumber(getTasaInteres(data?.tasa_interes)) + ' %'}</div>
              </div>

              <div className="row">
                <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Total pagado'}</div>
                <div className="col-xl-4 col-lg-5 info-entregas-variable">
                  {coopConfig.currency()?.symbol + ' ' + formatNumber(data?.total_anticipos_recuperados || 0)}
                </div>
              </div>

              <div className="row">
                <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Pendiente por pagar'}</div>
                <div className="col-xl-4 col-lg-5 info-entregas-variable">
                  {coopConfig.currency()?.symbol + ' ' + formatNumber(data?.total_saldo || 0)}
                </div>
              </div>
            </div>

            <div className="row my-4">
              <div className="col-12 mt-3">
                <TextField
                  type="date"
                  name="fecha"
                  error={!!errors?.fecha}
                  label="Fecha"
                  className="form-control"
                  value={fecha ?? ''}
                  onChange={event => {
                    setFecha(event.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className="col-12 mt-4">{errors?.fecha ? <div className="invalid-feedback">{errors.fecha}</div> : <span></span>}</div>
            </div>

            <div className="row my-4">
              <div className="col-12">
                <TextField
                  type="number"
                  name="cantidad"
                  error={!!errors?.cantidad}
                  label="Monto"
                  className="form-control"
                  value={cantidad ?? ''}
                  onChange={event => {
                    setCantidad(event.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                {errors?.cantidad ? <div className="invalid-feedback">{errors.cantidad}</div> : <span></span>}
              </div>
            </div>

            <div className="row my-4">
              <div className="col-12">
                <TextField
                  type="number"
                  name="comision_efectivo"
                  error={!!errors?.comision_efectivo}
                  label="Comisión"
                  className="form-control"
                  value={comisionEfectivo ?? ''}
                  onChange={event => {
                    setComisionEfectivo(event.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                {errors?.comision_efectivo ? <div className="invalid-feedback">{errors.comision_efectivo}</div> : <span></span>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
            {!blocking && (
              <>
                <button
                  onClick={() => closeHandler()}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
                <button
                  onClick={() => validateHandler()}
                  type="button"
                  className="btn btn-outline-secondary btn-sirio"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-save"></i> Guardar
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalCreditoProductorAbono;
