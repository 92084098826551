// Listar A, B, C o listar 0A, 0B en caso de ser número más grande
const getAlphaOrder = (n, total) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  if (total >= alphabet.length) {
    return Math.floor(0 + n / alphabet.length).toString() + alphabet[n % alphabet.length];
  }
  return alphabet[n];
};

export default getAlphaOrder;
