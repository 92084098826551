import { useState, useEffect } from 'react';
import Api from '../api/global.service';

const useGetColindancias = ({ uuid, setBlocking }) => {
  const [colindancias, setColindancias] = useState({ norte: null, sur: null, este: null, oeste: null });

  useEffect(() => {
    if (uuid != null) {
      setBlocking(true);

      Api.get('parcelas_colindancias', uuid)
        .then(response => {
          if (response.data.length > 0) {
            // TODO: Falta extraer info Fertilizantes, pesticidadas, etc.
            const norte = {
              colindante: response.data[0].colindante_norte,
              colindante_otro: response.data[0].colindante_norte_otro,
              propietario: response.data[0].colindante_prop_norte,
              herbicidas_nombre: response.data[0].cultivosnorte_herbicidas_nombre,
              fertilizantequim_nombre: response.data[0].cultivosnorte_fertilizantequim_nombre,
              plaguicida_nombre: response.data[0].cultivosnorte_plaguicida_nombre,
              amortiguamiento: response.data[0].amortiguamiento_norte,
            };
            const sur = {
              colindante: response.data[0].colindante_sur,
              colindante_otro: response.data[0].colindante_sur_otro,
              propietario: response.data[0].colindante_prop_sur,
              herbicidas_nombre: response.data[0].cultivossur_herbicidas_nombre,
              fertilizantequim_nombre: response.data[0].cultivossur_fertilizantequim_nombre,
              plaguicida_nombre: response.data[0].cultivossur_plaguicida_nombre,
              amortiguamiento: response.data[0].amortiguamiento_sur,
            };
            const este = {
              colindante: response.data[0].colindante_este,
              colindante_otro: response.data[0].colindante_este_otro,
              propietario: response.data[0].colindante_prop_este,
              herbicidas_nombre: response.data[0].cultivoseste_herbicidas_nombre,
              fertilizantequim_nombre: response.data[0].cultivoseste_fertilizantequim_nombre,
              plaguicida_nombre: response.data[0].cultivoseste_plaguicida_nombre,
              amortiguamiento: response.data[0].amortiguamiento_este,
            };
            const oeste = {
              colindante: response.data[0].colindante_oeste,
              colindante_otro: response.data[0].colindante_oeste_otro,
              propietario: response.data[0].colindante_prop_oeste,
              herbicidas_nombre: response.data[0].cultivosoeste_herbicidas_nombre,
              fertilizantequim_nombre: response.data[0].cultivosoeste_fertilizantequim_nombre,
              plaguicida_nombre: response.data[0].cultivosoeste_plaguicida_nombre,
              amortiguamiento: response.data[0].amortiguamiento_oeste,
            };

            setColindancias({
              norte: norte,
              sur: sur,
              este: este,
              oeste: oeste,
            });
          }
          setBlocking(false);
        })
        .catch(e => {
          setBlocking(false);
          // eslint-disable-next-line no-console
          console.log('error: ', e);
        });
    }
  }, [uuid]);
  return {
    colindancias,
  };
};

export default useGetColindancias;
