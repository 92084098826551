import xlsx from "json-as-xlsx";
import moment from 'moment';

const LotesExport = (data,ciclo,estados) => {
    let xlsData = [
      {
        sheet: 'Lotes',
        columns: [
          { label: 'NOMBRE', value: 'nombre' },
          { label: 'CÓDIGO', value: 'folio' },
          { label: "VOLUMEN A ENTREGAR", value: (row) => (parseFloat(row.volumen) >= 0) ? parseFloat(row.volumen).toFixed(2) : null },
          { label: "VOLUMEN ACOPIADO", value: (row) => (parseFloat(row.lote_kgs_entregados) >= 0) ? parseFloat(row.lote_kgs_entregados).toFixed(2) : null },
          { label: "AVANCE ACOPIO", value: (row) => (Number(row.volumen) > 0) ? ((Number(row.lote_kgs_entregados) / Number(row.volumen)) * 100).toFixed(2) + ' %' : null
        },
          { label: 'ESTADO', value: (row) => estados.find(it => it.value == row.id_recoleccion_acopio_estatus).label },
          { label: 'FECHA DE ENTREGA O VENTA', value: 'fecha_entrega_venta' }
        ],
        content: data,
      },
    ];

    let settings = {
      fileName: `Lotes_${ciclo.id}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData, settings);
  };

  export default LotesExport;
