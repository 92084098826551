import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import Api from '../../api/global.service';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ExampleCustomInput from './input';
import * as yup from 'yup';
import { publish } from '../events';
import Moment from 'moment';
import { components } from 'react-select';
import SelectFromApi from '../../components/select/select-from-api.jsx';
import CreatableSelect from 'react-select/creatable';
import { es } from 'date-fns/locale';

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <i className="fa fa-search" style={{ position: 'absolute', left: 6, padding: 10 }}></i>}
        {children}
      </components.ValueContainer>
    )
  );
};

const ModalEntrega = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);

  const [tipoEstado, setTipoEstado] = useState(0);
  const [folio, setFolio] = useState('');
  const [isNewfolio, setIsNewFolio] = useState(false);
  const [fecha, setFecha] = useState(null);
  const [idCiclo, setIdCiclo] = useState(0);
  const [idAcopios, setIdAcopios] = useState('');
  const [folios, setFolios] = useState([]);

  const [errors, setErrors] = useState(null);
  const schema = yup.object().shape({
    tipo_estado: yup.string().required('Debe seleccionar un estado'),
    folio: yup.string().required('Debe escribir un folio'),
    fecha: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe seleccionar una fecha'),
  });

  const selectFolioRef = useRef();

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
    setIdCiclo: id => setIdCiclo(id),
    setIdsAcopios: ids => setIdAcopios(ids),
    setFolios: folios => setFolios(folios),
  }));

  const showHandler = () => {
    setShow(true);
    setFolio('');
    setFecha(null);
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const folioChangeHandler = event => {
    if (event != null) {
      setFolio(event.value);
      if (event.__isNew__) {
        setIsNewFolio(true);
        setFecha(null);
      } else {
        setIsNewFolio(false);
        setFecha(Date.parse(event.date));
      }
    } else {
      setFolio('');
      setFecha(null);
    }
  };

  const validateHandler = () => {
    var data = {
      tipo_estado: tipoEstado,
      folio: tipoEstado === 1 ? folio : 'S/F',
      fecha: fecha,
    };

    schema
      .validate(data, { abortEarly: false })
      .then(function(valid) {
        //Datos validos
        const data = {
          ids_acopio: idAcopios,
          tipo_estado: tipoEstado,
          folio: tipoEstado === 1 ? folio : '',
          fecha: Moment(fecha).format('yyyy-MM-DD'),
          id_ciclo: idCiclo,
        };

        setBlocking(true);

        Api.create('admin/acopios/recoleccion_acopios_batch', data)
          .then(response => {
            setBlocking(false);
            setShow(false);

            publish('Entrega::onSave', data);

            if (tipoEstado === 1) {
              selectFolioRef.current.select.select.clearValue();
            }
          })
          .catch(e => {
            setBlocking(false);
          });
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
      });
  };

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div className="modal-dialog" style={{ top: 50, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 100px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit' }}>
            <h4 className="modal-title">{props.title}</h4>
            <button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row my-4">
              <div className="col-12">
                <SelectFromApi
                  id="tipo_estado"
                  name="tipo_estado"
                  value={tipoEstado}
                  placeholder="Seleccione un estado"
                  url="recoleccion_acopios_estatus"
                  keyValue="id"
                  keyLabel="nombre"
                  /*showLoading={false}*/ setFilter={e => {
                    setTipoEstado(e);
                    setFolio('');
                  }}
                />
                {errors?.tipo_estado && <div className="invalid-feedback">{errors?.tipo_estado}</div>}
              </div>
            </div>

            {tipoEstado === 1 && (
              <div className="row my-4">
                <div className="col-12">
                  <CreatableSelect
                    ref={selectFolioRef}
                    options={folios}
                    name="folio"
                    placeholder="Folio de entrega"
                    isSearchable={true}
                    onChange={event => folioChangeHandler(event)}
                    components={{ ValueContainer }}
                    isClearable={true}
                    formatCreateLabel={input => {
                      return 'Crear "' + input + '"';
                    }}
                    styles={{
                      valueContainer: base => ({
                        ...base,
                        paddingLeft: 38,
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: 18,
                      }),
                      placeholder: base => ({
                        ...base,
                        fontSize: 18,
                      }),
                      fontSize: 18,
                    }}
                  />
                  {errors?.folio && <div className="invalid-feedback">{errors?.folio}</div>}
                </div>
              </div>
            )}

            <div className="row my-4">
              <div className="col-12">
                <DatePicker
                  customInput={<ExampleCustomInput onChange={setFecha} />}
                  dateFormat={'dd/MMM/yyyy'}
                  selected={fecha}
                  onChange={setFecha}
                  wrapperClassName="w-100"
                  readOnly={tipoEstado === 1 && !isNewfolio && folio !== ''}
                  locale={es}
                  placeholderText="Seleccione una fecha"
                  isClearable
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                />
                {errors?.fecha && <div className="invalid-feedback">{errors?.fecha}</div>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
            {!blocking && (
              <>
                <button
                  onClick={() => closeHandler()}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
                <button
                  onClick={() => validateHandler()}
                  type="button"
                  className="btn btn-outline-secondary btn-sirio"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-save"></i> Guardar estado
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalEntrega;
