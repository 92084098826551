import React, { useState, useEffect } from 'react';
// import CardContainer from '../../../components/card/card-container.jsx';
import Api from '../../../api/global.service';
import env from '../../../environment.js';
import BlockUi from 'react-block-ui';
import LabelCategory from '../../../components/label/label-category.jsx';
import { publish } from '../../../components/events.js';
import formatNumber from '../../../utils/formatNumber.js';

import { useCoopConfig, getIdCatCreditoPrecosecha, getUsaEstimacionManual } from '../../../components/content/coop-config.jsx';

import transformWord from '../../../utils/transformWord.js';
import DebtCalculator from '../../../nuup/utils/debtCalculator.js';

const EntregasXProductorInfo = props => {
  const coopConfig = useCoopConfig();
  const [blocking, setBlocking] = useState(true);
  const [cuenta, setCuenta] = useState([]);
  const [fotoURL, setFotoURL] = useState(false);
  const [fotoOpen, setFotoOpen] = useState(false);
  const [estimacion, setEstimacion] = useState();
  const [entregas, setEntregas] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [creditos, setcreditos] = useState([]);
  const [etiquetaAnticipos, setEtiquetaAnticipos] = useState('-');

  useEffect(() => {
    getEtiquetaAnticipo();
  }, [coopConfig.config]);

  const getEtiquetaAnticipo = async () => {
    let etiqueta = await coopConfig.etiquetaAnticipos();
    setEtiquetaAnticipos(etiqueta);
  };

  useEffect(() => {
    getData();
    updateData();
  }, []);

  const getData = () => {
    Api.get('admin/acopios/entregas/acopios_por_productor/' + localStorage.getItem(`${env.appName}-ciclo_uuid`), props.uuid_cuenta)
      .then(response => {
        setBlocking(false);
        if (typeof response.data[0] !== 'undefined') {
          let _cuenta = {
            cuenta_categoria: response.data[0].cuenta_categoria,
            cuenta_categoria_color: response.data[0].cuenta_categoria_color,
            productor_clave_tx: response.data[0].productor_clave_tx,
            productor_codigo_tx: response.data[0].productor_codigo_tx,
            productor_id: response.data[0].productor_id,
            productor_nombre: response.data[0].productor_nombre,
            productor_uuid: response.data[0].productor_uuid,
            region: response.data[0].region,
            localidad: response.data[0].localidad,
            municipio: response.data[0].municipio,
          };
          let _entregas = {
            entregas_no: response.data[0].entregas_no,
            entregas_no_bultos: response.data[0].entregas_no_bultos,
            entregas_peso_neto_total: response.data[0].entregas_peso_neto_total,
            precio_por_kg_promedio: response.data[0].precio_por_kg_promedio,
          };

          let _pagos = {
            pagos_descuentos_total: response.data[0].pagos_descuentos_total,
            pagos_pagado_total: response.data[0].pagos_pagado_total,
            pagos_total: response.data[0].pagos_total,
          };

          let _estimacion = {
            est_peso_estimado_total: getUsaEstimacionManual()
              ? response.data[0].est_peso_estimado_manual_total
              : response.data[0].est_peso_estimado_total,
            // est_peso_estimado_total_nueva_estructura: response.data[0].est_peso_estimado_total_nueva_estructura,
            est_entregado_vs_estimacion: response.data[0].est_entregado_vs_estimacion,
          };

          setCuenta(_cuenta);
          setEntregas(_entregas);
          setEstimacion(_estimacion);
          setPagos(_pagos);

          publish('Entrega::Cuenta', _cuenta);
          publish('Entrega::Entregas', _entregas);
          publish('Entrega::Estimacion', _estimacion);
          publish('Entrega::Pagos', _pagos);
        }
        setBlocking(false);
      })
      .catch(e => {
        // console.log('e', e);
        setBlocking(false);
      });
  };

  const updateData = () => {
    setBlocking(true);
    Api.Acopio.search('anticipos_por_productor', {
      filter: [
        { column: 'id_ciclo', operator: '=', value: localStorage.getItem(`${env.appName}-ciclo_id`) },
        { column: 'productor_uuid', operator: '=', value: props.uuid_cuenta },
      ],
    })
      .then(response => {
        const updatedData = response.data.map(anticipo => {
          // RETURN DATA WITH DEBT CALCULATION
          if (getIdCatCreditoPrecosecha() > 2) {
            const debtCalc = new DebtCalculator({
              credito: anticipo.credito,
              interestRate: +anticipo.tasa_interes,
              pagos: anticipo.pagos_og,
              stringDate: null,
            });
            const calc = debtCalc.getDebt();
            const estatus = getEstatus(calc.debtToday.pendingCapital / 100);
            return {
              ...anticipo,
              total_saldo: calc.debtToday.pendingCapital / 100,
              total: +calc.debtToday.totalInterest / 100 + +anticipo.cantidad_anticipo,
              total_interes: calc.debtToday.totalInterest / 100,
              estatus: estatus.label,
              estatus_color: estatus.color,
            };
          }
          // RETURN DATA WITH NO INTEREST RATE
          const estatus = getEstatus(+anticipo.cantidad_anticipo - +anticipo.total_anticipos_recuperados);
          return {
            ...anticipo,
            total: +anticipo.cantidad_anticipo,
            total_interes: 0,
            total_saldo: +anticipo.cantidad_anticipo - +anticipo.total_anticipos_recuperados,
            estatus: estatus.label,
            estatus_color: estatus.color,
          };
        });

        let _creditos_raw = updatedData.map(function(obj) {
          return {
            anticipo_id: obj.anticipo_id,
            total_saldo: obj.total_saldo,
          };
        });

        let _creditos = {
          creditos_no: updatedData.length,
          creditos_total:
            updatedData.reduce(function(tot, arr) {
              return tot + arr.total;
            }, 0) -
            updatedData.reduce(function(tot, arr) {
              return tot + arr.total_interes;
            }, 0),
          total_saldo: updatedData.reduce(function(tot, arr) {
            return tot + arr.total_saldo;
          }, 0),
          raw: _creditos_raw,
        };

        setcreditos(_creditos);
        publish('Entrega::Creditos', _creditos);

        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        // console.log('error: ', e);
        setBlocking(false);
      });
  };

  const getEstatus = saldo => {
    if (Number(saldo) >= 0.01) {
      return { label: 'Vigente', color: '#e74c3c' };
    }
    return { label: 'Pasado', color: '#27ae60' };
  };

  const getEstimacionVsEntregas = (estimado, entregado) => {
    let result = 0;
    if (estimado > 0 && entregado) {
      result = formatNumber((entregado / estimado) * 100);
    }
    return result;
  };

  const getSaldoEntregas = (pagos_total, pagos_descuentos_total, pagos_pagado_total) => {
    return formatNumber((pagos_total || 0) - (pagos_descuentos_total || 0) - (pagos_pagado_total || 0));
  };

  return (
    <div className="col-xl-12 col-lg-12" style={{ backgroundColor: '#fcfcfc' }}>
      <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
        <div className="row">
          <div className="col-xl-5 col-lg-6 text-center">
            {fotoURL ? (
              <button type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }} onClick={() => setFotoOpen(!fotoOpen)}>
                <img src={`${env.apiURL}/${fotoURL}`} className="profile_productor"></img>
              </button>
            ) : (
              <img src={env.images.profile.productor} />
            )}
          </div>

          <div className="col-xl-7 col-lg-6">
            <div className="row" style={{ paddingTop: 16 }}>
              <div className="col-xl-12 col-lg-12">
                <h6 className="font-lato-normal-400">Productor:</h6>
              </div>
              <div className="col-xl-12 col-lg-12">
                <h4 className="font-lato-normal-500 pb-3">{cuenta?.productor_nombre}</h4>
              </div>
              <LabelCategory label={cuenta?.cuenta_categoria} color={cuenta?.cuenta_categoria_color}></LabelCategory>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-5 offset-1">{cuenta?.productor_clave_tx}</div>
          <div className="col-xl-7 col-lg-6">{cuenta?.municipio}</div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-5 offset-1">{cuenta?.productor_codigo_tx}</div>
          <div className="col-xl-7 col-lg-6">{cuenta?.localidad}</div>
        </div>

        <div className="btn-entregas-acopios">Información de entregas y acopio</div>

        <div className="pl-4 pt-3">
          <div className="row">
            <div className="col-xl-8 col-lg-12 info-entregas-titulo">
              {'Entregas totales (' + formatNumber(entregas?.entregas_no || 0) + ')'}
            </div>
            {/*
                  <div className='col-xl-4 info-entregas-link'>
                    {'Ver recibos'}
                  </div>
                */}
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Nº bultos'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">{entregas?.entregas_no_bultos}</div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Peso neto total'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {formatNumber(entregas?.entregas_peso_neto_total || 0) + ' ' + coopConfig.acopioUnits()?.abreviacion_plural}
            </div>
          </div>
        </div>

        <div className="pl-4 pt-3">
          <div className="row">
            <div className="col-xl-12 info-entregas-titulo">{'Estimación de cosecha'}</div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Peso estimado total'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {formatNumber(estimacion?.est_peso_estimado_total || 0) + ' ' + coopConfig.acopioUnits()?.abreviacion_plural}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'% entregado vs. estimación'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {getEstimacionVsEntregas(estimacion?.est_peso_estimado_total || 0, entregas?.entregas_peso_neto_total) + ' %'}
            </div>
          </div>
        </div>

        <div className="pl-4 pt-3">
          <div className="row">
            <div className="col-xl-12 info-entregas-titulo">{'Pagos totales'}</div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Precio promedio a productor'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {coopConfig.currency()?.symbol + ' ' + formatNumber(entregas?.precio_por_kg_promedio || 0)}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Importe total'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {coopConfig.currency()?.symbol + ' ' + formatNumber(pagos?.pagos_total || 0)}
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Descuentos deudas'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {coopConfig.currency()?.symbol + ' ' + formatNumber(pagos?.pagos_descuentos_total || 0)}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Pagado a productor'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {coopConfig.currency()?.symbol + ' ' + formatNumber(pagos?.pagos_pagado_total || 0)}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Saldo de las entregas'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {coopConfig.currency()?.symbol +
                ' ' +
                getSaldoEntregas(pagos?.pagos_total || 0, pagos?.pagos_descuentos_total || 0, pagos?.pagos_pagado_total || 0)}
            </div>
          </div>
        </div>
        {getIdCatCreditoPrecosecha() > 1 && creditos?.creditos_no > 0 && (
          <div className="pl-4 pt-3">
            <div className="row">
              <div className="col-xl-8 col-lg-6 info-entregas-titulo">
                {transformWord(etiquetaAnticipos, ['plural', 'capitalize']) +
                  ' precosecha (' +
                  formatNumber(creditos?.creditos_no || 0) +
                  ')'}
              </div>
              <div className="col-xl-4 col-lg-6 info-entregas-link">{''}</div>
            </div>

            <div className="row">
              <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Importe total'}</div>
              <div className="col-xl-4 col-lg-5 info-entregas-variable">
                {coopConfig.currency()?.symbol + ' ' + formatNumber(creditos?.creditos_total || 0, 2)}
              </div>
            </div>

            <div className="row">
              <div className="col-xl-8 col-lg-7 info-entregas-variable">
                {'Saldo del ' + transformWord(etiquetaAnticipos, ['singular'])}
              </div>
              <div className="col-xl-4 col-lg-5 info-entregas-variable">
                {coopConfig.currency()?.symbol + ' ' + formatNumber(creditos?.total_saldo || 0)}
              </div>
            </div>
          </div>
        )}
      </BlockUi>
    </div>
  );
};

export default EntregasXProductorInfo;
