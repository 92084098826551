import xlsx from 'json-as-xlsx';
import moment from 'moment';

const LotesEntregasExport = (data, nombre_lote, mostrarCostalera = false) => {
  const agregaEstatusCostalera = xlsData => {
    if (mostrarCostalera) {
      xlsData[0].columns.push({
        label: 'ESTATUS COSTALERA',
        value: row => (row.nombre_cat_costaleras_estatus ? row.nombre_cat_costaleras_estatus : '-'),
      });
    }
    return xlsData;
  };

  let xlsData = [
    {
      sheet: 'Entregas',
      columns: [
        { label: 'FECHA ENTREGA', value: 'fecha_acopio' },
        { label: 'FOLIO ENTREGA', value: 'folio_acopio' },
        { label: 'FOLIO/LOTE', value: 'nombre_lote' },
        { label: 'ESTADO', value: 'estatus' },
        { label: 'CLAVE', value: 'productor_clave_tx' },
        { label: 'CÓDIGO', value: 'productor_codigo_tx' },
        { label: 'NOMBRE PRODUCTOR', value: 'productor_nombre' },
        { label: 'LOCALIDAD', value: 'localidad' },
        { label: 'REGIÓN', value: 'region' },
        { label: 'CENTRO DE ACOPIO', value: 'centro_acopio' },
        { label: 'CATEGORÍA', value: 'cuenta_categoria' },
        { label: 'TIPO DE CAFÉ', value: 'tipo_cafe' },
        { label: 'Nº RECIPIENTES', value: 'no_bultos' },
        { label: 'PESO BRUTO (KGS)', value: 'peso_bruto' },
        { label: 'PESO TARA (KGS)', value: 'peso_tara' },
        { label: 'PESO NETO (KGS)', value: 'peso_neto' },
        { label: 'PRECIO A PRODUCTOR ($/KG)', value: 'precio_por_kg' },
        { label: 'IMPORTE TOTAL ($)', value: 'importe_total' },
        { label: 'DESCUENTOS DEUDAS', value: 'total_anticipos_recuperados' },
        { label: 'PAGADO A PRODUCTOR ($)', value: 'pagado_a_productor' },
        { label: 'SALDO DE LA ENTREGA ($)', value: 'total_saldo' },
      ],
      content: data,
    },
  ];

  let settings = {
    fileName: `Entregas_${nombre_lote}_${moment().format('YYYYMMDD_HHmmss')}`,
  };
  xlsx(agregaEstatusCostalera(xlsData), settings);
};

export default LotesEntregasExport;
