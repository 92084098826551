import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CardBasic from '../../components/card/card-basic.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelColor from '../../components/label/label-color.jsx';
import LabelRisk from '../../components/label/label-risk.jsx';
import Api from '../../api/global.service';
import BlockUi from 'react-block-ui';
import env from '../../environment.js';

import NoPagination from '../../components/pagination/noPagination.jsx';
import SelectCiclo from '../../components/select/select-ciclo.jsx';

import GoogleMapReact from 'google-map-react';
import MarkerCircle from '../../components/map/marker-circle.jsx';
import MarkerLabel from '../../components/map/marker-label.jsx';
import descargaKML from '../../utils/downloadKML.js';
// import Marker from '../../components/map/marker.jsx';

const PadronMapasList = () => {
  const [idCiclo, setIdCiclo] = useState(3);

  const [dataLocalidades, setDataLocalidades] = useState([]);
  const [blockingLocalidades, setBlockingLocalidades] = useState(true);
  const [zoomLocalidades, setZoomLocalidades] = useState(9);
  const [centerLocalidades, setCenterLocalidades] = useState({
    lat: 17.040386,
    lng: -91.842369,
  });

  const [localidad, setLocalidad] = useState(null);

  const [dataProductores, setDataProductores] = useState([]);
  const [dataProductoresLocalidad, setDataProductoresLocalidad] = useState([]);
  const [blockingProductores, setBlockingProductores] = useState(true);

  const [mapProductores, setMapProductores] = useState(null);
  const [mapsProductores, setMapsProductores] = useState(null);
  const [zoomProductores, setZoomProductores] = useState(9);
  const [centerProductores, setCenterProductores] = useState({
    lat: 17.040386,
    lng: -91.842369,
  });

  const [poligonosActuales, setPoligonosActuales] = useState([]);
  const [poligonosOld, setPoligonosOld] = useState([]);
  const [topHeight] = useState(520);

  const handleChange = (event, localidad_uuid) => {
    let value = event.target.value;
    if (value > 0) {
      Api.update('localidades', localidad_uuid, {
        id_cat_localidad_riesgo: value,
      })
        .then(() => {
          getDataLocalidades();
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  const SelectRiesgos = ({ localidad_uuid }) => {
    return (
      <select
        style={{ maxHeight: 25, padding: 0, maxWidth: 75 }}
        className="form-control"
        onChange={event => handleChange(event, localidad_uuid)}>
        <option value={0}></option>
        <option value={1}>Bajo</option>
        <option value={2}>Medio</option>
        <option value={3}>Alto</option>
      </select>
    );
  };

  const columnsLocalidades = React.useMemo(
    () => [
      {
        Header: 'CLAVE',
        accessor: 'id_localidad',
        sortable: true,
        minWidth: 50,
      },
      {
        Header: 'COMUNIDAD',
        accessor: 'localidad',
        sortable: true,
        minWidth: 50,
        Cell: ({ row, value }) => (
          <a onClick={() => goToLocalidad(row.original)} className="m-r-2" style={{ color: '#789D16', cursor: 'pointer' }}>
            <span>{value}</span>
          </a>
        ),
      },
      {
        Header: 'MUNICIPIO',
        accessor: 'municipio',
        sortable: true,
        minWidth: 50,
      },
      {
        Header: 'RIESGO',
        accessor: 'cat_localidad_riesgo',
        sortable: true,
        width: 85,
        Cell: ({ row, value }) => {
          if (row.original.id_cat_localidad_riesgo === null) {
            return <SelectRiesgos localidad_uuid={row.original.localidad_uuid} />;
          } else {
            return (
              <LabelRisk
                uuid={row.original.localidad_uuid}
                id={row.original.id_cat_localidad_riesgo}
                label={value}
                color={row.original.cat_localidad_riesgo_color}></LabelRisk>
            );
          }
        },
      },
    ],
    []
  );

  const columnsProductores = React.useMemo(
    () => [
      {
        Header: 'PRODUCTOR',
        accessor: 'productor_nombre',
        sortable: true,
        minWidth: 50,
        // Cell: cell => (
        // 	// <div className="with-btn text-nowrap">
        // 		<a onClick={() => goTo(cell.row.original)} className="m-r-2" style={{color: '#789D16', cursor: 'pointer'}}>
        // 			<span>{cell.value}</span>
        // 		</a>
        // 	// </div>
        // )
      },
      {
        Header: 'NOMBRE PARCELA',
        accessor: 'parcela_nombre',
        sortable: true,
        minWidth: 5,
        Cell: ({ row, value }) => {
          if (row.original.centroide_lat != null && row.original.centroide_lng != null) {
            return (
              <a onClick={() => goToParcela(row.original)} className="m-r-2" style={{ color: '#789D16', cursor: 'pointer' }}>
                <span>{value}</span>
              </a>
            );
          } else {
            return value;
          }
        },
      },
      {
        Header: 'CATEGORÍA',
        accessor: 'cuenta_categoria',
        sortable: true,
        minWidth: 90,
        Cell: ({ row, value }) => <LabelCategory label={value} color={row.original.cuenta_categoria_color}></LabelCategory>,
      },
      {
        Header: 'RIESGO',
        accessor: 'nivel_riesgo',
        sortable: true,
        minWidth: 90,
        Cell: ({ row, value }) => <LabelColor label={value} color={row.original.nivel_riesgo_color}></LabelColor>,
      },
      // {
      // 	Header: 'NOMBRE PRODUCTOR',
      // 	accessor: 'productor_nombre',
      // 	sortable: true,
      // 	minWidth: 50,
      // 	Cell: cell => (
      // 		// <div className="with-btn text-nowrap">
      // 			<Link to={{pathname: `/admin/cuentas/perfil/${cell.row.original.productor_uuid}`}} className="m-r-2" style={{color: '#789D16'}}>
      // 				<span>{cell.value}</span>
      // 			</Link>
      // 		// </div>
      // 	)
      // },
    ],
    []
  );

  const goToLocalidad = data => {
    let center = { lat: Number(data.localidad_latitud), lng: Number(data.localidad_longitud) };
    setLocalidad(data);
    setCenterLocalidades(center);
    setCenterProductores(center);
    setZoomLocalidades(14);
    setZoomProductores(14);

    setBlockingProductores(true);

    const view = 'tx_padron_mapas_detalle';

    Api.search(view, {
      filter: [
        { column: 'id_localidad', operator: '=', value: data.id_localidad },
        { column: 'id_ciclo', operator: '=', value: data.id_ciclo },
      ],
    })
      .then(response => {
        console.log(response.data);
        setDataProductoresLocalidad(response.data);
        setBlockingProductores(false);
      })
      .catch(e => {
        setBlockingProductores(false);
      });
  };

  const goToParcela = data => {
    if (data.centroide_lat !== null && data.centroide_lng !== null) {
      // TODO: lat/lng info is inverted?
      let centerMap = { lat: Number(data.centroide_lat), lng: Number(data.centroide_lng) };
      setCenterProductores(centerMap);
      setZoomProductores(18);
    }
  };

  useEffect(() => {
    if (mapProductores !== null && mapsProductores !== null && dataProductoresLocalidad !== null) {
      let p = dataProductoresLocalidad
        .filter(productor => {
          return productor.poligono !== null;
        })
        .map(productor => {
          let poligono = JSON.parse(productor.poligono);

          let poligono_data = poligono.coordinates[0].map(function(p) {
            return { lat: p[1], lng: p[0] };
          });

          var polygon = new mapsProductores.Polygon({
            paths: poligono_data,
            strokeColor: '#ffffff', //parcela.cuenta_categoria_color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#ffffff', //parcela.cuenta_categoria_color,
            fillOpacity: 0.35,
          });
          polygon.setMap(mapProductores);

          return polygon;
        });

      setPoligonosActuales(p);
    }
  }, [mapProductores, mapsProductores, dataProductoresLocalidad]);

  useEffect(() => {
    Api.search('tx_padron_mapas_detalle', {
      filter: [{ column: 'id_ciclo', operator: '=', value: localStorage.getItem(`${env.appName}-ciclo_id`) }],
    })
      .then(response => {
        setDataProductores(response.data);
      })
      .catch(e => {
        //setBlockingProductores(false);
      });
  }, [idCiclo]);

  useEffect(() => {
    //Oculta poligonos anteriores
    poligonosOld.forEach(polygon => {
      polygon.setMap(null);
    });

    setPoligonosOld(poligonosActuales);
  }, [poligonosActuales]);

  const renderMapProductores = (map, maps) => {
    setMapProductores(map);
    setMapsProductores(maps);
  };

  const getDataLocalidades = () => {
    setBlockingLocalidades(true);
    setLocalidad(null);

    const view = 'tx_padron_mapas';
    Api.search(view, {
      filter: [{ column: 'id_ciclo', operator: '=', value: idCiclo }],
    })
      .then(response => {
        setDataLocalidades(response.data);
        setBlockingLocalidades(false);
        if (response.data.length > 0) {
          let center = {
            lat: Number(response.data[0].localidad_latitud),
            lng: Number(response.data[0].localidad_longitud),
          };
          setCenterLocalidades(center);
        }
      })
      .catch(e => {
        setBlockingLocalidades(false);
      });
  };

  useEffect(() => {
    if (idCiclo > 0) {
      getDataLocalidades();
    }
  }, [idCiclo]);

  const onChangeMap = event => {
    setZoomProductores(event.zoom);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/padron">Padrón</Link>
          </li>
          <li className="breadcrumb-item active">Padrón mapas</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setIdCiclo(evt.target.id);
          }}></SelectCiclo>
      </Breadcrumb>

      <div className="row pb-4">
        <div className="col-xl-12">
          <CardContainer height={topHeight + 100}>
            <div className="row pb-4">
              <div className="col-xl-7">
                <BlockUi tag="div" message="" blocking={blockingLocalidades} renderChildren={true}>
                  <div id="GoogleMapReact" className="map-rounded" style={{ height: '450px', width: '100%' }}>
                    {dataLocalidades.length != 0 && (
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: env.googleApiKey }}
                        options={map => ({ mapTypeId: map.MapTypeId.SATELLITE })}
                        center={centerLocalidades}
                        zoom={zoomLocalidades}>
                        {dataLocalidades.map((localidad, i) => (
                          <MarkerCircle
                            key={i}
                            lat={localidad.localidad_latitud}
                            lng={localidad.localidad_longitud}
                            color={localidad.cat_localidad_riesgo_color}
                            label={localidad.localidad}></MarkerCircle>
                        ))}
                      </GoogleMapReact>
                    )}
                    {dataLocalidades.length === 0 && (
                      <CardBasic
                        height={topHeight - 60}
                        label="Ubicaciónes no disponibles"
                        value={':('}
                        style={{ backgroundColor: env.colors.neutral_1 }}></CardBasic>
                    )}
                    <div style={{ paddingLeft: 10, paddingTop: 25 }}>
                      <img style={{ paddingLeft: 10 }} src={env.images.icon.comunidad} />
                      <span style={{ paddingLeft: 10 }}>Comunidades</span>
                      {/*
                        <img style={{paddingLeft : 10}} src={env.images.icon.sede} />
                        <span style={{paddingLeft : 10}}>Sede de la organización</span>
                        */}
                    </div>
                  </div>
                </BlockUi>
              </div>

              <div className="col-xl-5">
                {dataProductores.length > 0 && (
                  <div className="float-xl-right">
                    <p>
                      <img src={env.images.icon.kml} />
                      <a
                        onClick={() => descargaKML(dataProductores)}
                        style={{
                          padding: 10,
                          color: '#789D16',
                          cursor: 'pointer',
                        }}>
                        {'Descargar Mapa KML'}
                      </a>
                    </p>
                  </div>
                )}

                <NoPagination blocking={blockingLocalidades} columns={columnsLocalidades} data={dataLocalidades}></NoPagination>
              </div>
            </div>
          </CardContainer>
        </div>
      </div>

      {localidad !== null && (
        <div className="row pb-4">
          <div className="col-xl-12">
            <CardContainer height={topHeight + 150}>
              <div className="row">
                <div className="col-xl-7">
                  <BlockUi tag="div" message="" blocking={blockingProductores} renderChildren={true}>
                    <div className="map-rounded" style={{ height: '450px', width: '100%' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: env.googleApiKey }}
                        options={map => ({ mapTypeId: map.MapTypeId.SATELLITE })}
                        center={centerProductores}
                        zoom={zoomProductores}
                        onChange={event => onChangeMap(event)}
                        onGoogleApiLoaded={({ map, maps }) => renderMapProductores(map, maps)}>
                        <MarkerCircle
                          lat={localidad?.localidad_latitud}
                          lng={localidad?.localidad_longitud}
                          label={localidad?.localidad}></MarkerCircle>

                        {dataProductoresLocalidad
                          .filter(productor => productor.centroide_lat !== null)
                          .map((productor, i) => (
                            <MarkerLabel
                              key={i}
                              lat={Number(productor.centroide_lat)}
                              lng={Number(productor.centroide_lng)}
                              color={productor.cuenta_categoria_color}
                              label={productor.parcela_nombre}
                              visible={zoomProductores >= 18}
                              link={env.pathEudr + '/' + productor.parcela_uuid}></MarkerLabel>
                          ))}
                      </GoogleMapReact>
                    </div>
                  </BlockUi>
                </div>

                <div className="col-xl-5">
                  <h3 className="pb-2">
                    Comunidad: {localidad?.localidad}, {localidad?.municipio}
                  </h3>
                  <NoPagination blocking={blockingProductores} columns={columnsProductores} data={dataProductoresLocalidad}></NoPagination>
                </div>
              </div>
            </CardContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default PadronMapasList;
