import Swal from "sweetalert2";
import { Api } from "../app-controller";

const sharedNameForm = 'Usuario';
const sharedTable = 'users';
const sharedURL = '/admin/usuarios/list';

export const deleteUserByUUID = (uuid) => {
  Swal.fire({
    title: '¿Está seguro?',
    text: 'El registro se eliminará',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '¡Sí, eliminar!',
    allowOutsideClick: false,
  }).then(result => {
    if (result.isConfirmed) {
      Api.delete(sharedTable, uuid)
        .then(response => {
          Swal.close();
          Swal.fire({
            icon: 'success',
            title: '',
            text: `Se eliminó con éxito la ${sharedNameForm.toLowerCase()} -> ${uuid}`,
          });
          window.location.href = sharedURL;
        })
        .catch(e => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: e,
          });
        });
    }
  });
};