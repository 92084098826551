
import React from 'react';


class ExampleCustomInput extends React.Component {

  render() {
      const {value, onClick, placeholder = "Seleccione una fecha", onChange = () => {} } = this.props;
      return (
        <div className ="input-group p-4"> 
          <div className="input-group-prepend ml-4">
          </div>         
          <input type="text" className="form-control mr-2" value={value} onClick={onClick} placeholder={placeholder} onChange={onChange}/>
          <div className="input-group-append p-4">
            <i className="fas fa-calendar-day" onClick={onClick} style={{fontSize: '18px'}}></i>
          </div>
        </div>
      );
  }

}

export default ExampleCustomInput;
