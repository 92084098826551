import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Api from '../../api/global.service';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ExampleCustomInput from './input';
import * as yup from 'yup';
import Moment from 'moment';
import env from '../../environment.js';
import Select from 'react-select';
import { publish } from '../../components/events.js';
import validacionNumero2Decimales from '../../nuup/utils/validaciones/validacionNumero2Decimales.js';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import FormSelectMaterial from '../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import FormSelectMaterialApi from '../../nuup/componentes/Forms/FormSelectMaterialApi.jsx';

const ModalCreditoProductor = forwardRef((props, ref) => {
  const customStyles = {
    control: base => ({
      ...base,
      flexDirection: 'row-reverse',
      width: '100%',
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        fontSize: 18,
        fontWeight: 400,
      };
    },
  };

  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);

  const [fecha, setFecha] = useState(null);
  const [idCuenta, setIdCuenta] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [idCiclo, setIdCiclo] = useState(0);
  const [folio, setFolio] = useState('');
  const [cuentas, setCuentas] = useState([]);

  const [errors, setErrors] = useState(null);
  const schema = yup.object().shape({
    folio: yup.string().required('Debe escribir un folio'),
    fecha: yup
      .date()
      .max(moment().add(12, 'M'), 'Seleccione otra fecha')
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
      .required('Debe seleccionar una fecha'),
    id_cuenta: yup.string().required('Debe seleccionar un productor'),
    cantidad: validacionNumero2Decimales(0.01, { required: 'Debe escribir un monto' }),
  });

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
    setIdCiclo: id => setIdCiclo(id),
  }));

  useEffect(() => {
    getProductores();
  }, []);

  const showHandler = () => {
    setShow(true);
    setFolio('');
    setFecha('');
    setIdCuenta('');
    setCantidad('');
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const validateHandler = () => {
    var data = {
      folio: folio,
      fecha: fecha,
      id_cuenta: idCuenta,
      cantidad: cantidad,
    };
    var that = this;
    schema
      .validate(data, { abortEarly: false })
      .then(function(valid) {
        //Datos validos
        const data = {
          folio: folio,
          fecha_ejecucion: Moment(fecha).format('yyyy-MM-DD'),
          id_cuenta: idCuenta,
          cantidad_anticipo: cantidad,
          id_ciclos: idCiclo,
        };

        Api.Acopio.create('anticipos/storeweb', data)
          .then(response => {
            if (response.data.success) {
              setBlocking(false);
              setShow(false);
              publish('Anticipo::onSave', response.data.data);
            } else {
              let err = response.data.data;
              setErrors(err);
            }
          })
          .catch(e => {
            setBlocking(false);
          });
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
      });
  };

  const getProductores = () => {
    Api.search('cuentas', {
      filter: [{ column: 'id_ciclo', operator: '=', value: localStorage.getItem(`${env.appName}-ciclo_id`) }],
    }).then(response => {
      let _items = response.data.map(function(item) {
        return { value: item['id'], label: item['nombre'], uuid_cuenta: item['uuid'] };
      });
      setCuentas(_items);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  const handleChange = selected => {
    console.log(selected);
  };

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div className="modal-dialog" style={{ top: 0, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 50px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit' }}>
            <h4 className="modal-title">{props.title}</h4>
            <button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body" style={{ overflowY: 'auto' }}>
            <div className="row my-4">
              <div className="col-12">
                <TextField
                  type="text"
                  name="folio"
                  error={!!errors?.folio}
                  label="Folio"
                  className="form-control"
                  value={folio ?? ''}
                  onChange={event => {
                    setFolio(event.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">{errors?.folio ? <div className="invalid-feedback">{errors.folio}</div> : <span></span>}</div>
            </div>

            <div className="row my-4">
              <div className="col-12 mt-3" style={{ zIndex: 2000 }}>
                <TextField
                  type="date"
                  name="fecha"
                  error={!!errors?.fecha}
                  label="Fecha"
                  className="form-control"
                  value={fecha ?? ''}
                  onChange={event => {
                    setFecha(event.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className="col-12 mt-4">{errors?.fecha ? <div className="invalid-feedback">{errors.fecha}</div> : <span></span>}</div>
            </div>

            <div className="row my-4">
              <div className="col-12" style={{ zIndex: 1000 }}>
                <Select
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={true}
                  placeholder="Seleccione un productor"
                  noOptionsMessage={() => 'No hay elementos por mostrar'}
                  options={cuentas}
                  onChange={selected => setIdCuenta(selected.value)}
                />

                {errors?.id_cuenta && <div className="invalid-feedback">{errors?.id_cuenta}</div>}
              </div>
            </div>

            <div className="row my-4">
              <div className="col-12">
                <TextField
                  type="number"
                  name="cantidad"
                  error={!!errors?.cantidad}
                  label="Monto"
                  className="form-control"
                  value={cantidad ?? ''}
                  onChange={event => {
                    setCantidad(event.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                {errors?.cantidad ? <div className="invalid-feedback">{errors.cantidad}</div> : <span></span>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
            {!blocking && (
              <>
                <button
                  onClick={() => closeHandler()}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
                <button
                  onClick={() => validateHandler()}
                  type="button"
                  className="btn btn-outline-secondary btn-sirio"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-save"></i> Guardar
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalCreditoProductor;
