import React, { Component } from 'react';
import { Collapse, NavbarToggler, Nav } from 'reactstrap';
import { PageSettings } from '../../../config/page-settings';
import SidebarNav from '../../sidebar/sidebar-nav';

class DropdownMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({ toggleMobileTopMenu, pageMobileTopMenuCollapsed }) => (
          <React.Fragment>
            <Collapse isOpen={!pageMobileTopMenuCollapsed}>
              <Nav navbar style={{ display: 'flex', flexDirection: 'column' }}>
                <SidebarNav className="font-lato-normal-500" />
                <a
                  href={'https://sirioapp.com/index.php/acerca-de-sirio/'}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    fontSize: 'large',
                    textTransform: 'uppercase',
                    marginBottom: '1px',
                  }}
                  rel="noreferrer">
                  <i className="fa fa-info-circle mr-1" style={{ fontSize: '0.9rem' }}></i>
                  Acerca de Sirio
                </a>
              </Nav>
            </Collapse>
          </React.Fragment>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default DropdownMobile;
