import React from 'react';
import env from '../../environment';

const CardTitleParcela = ({ cuenta_uuid = null, cuenta_name, parcela_name, localidad, municipio }) => {
  return (
    <>
      <div className="col-xl-3 col-md-3">
        <img src={env.images.icon.parcela} style={{ height: '80px' }} />
      </div>
      <div className="col-xl-7 col-md-7">
        <h6 className="font-lato-normal-400">
          {cuenta_uuid ? (
            <a style={{ color: 'black' }} href={'/admin/cuentas/perfil/' + cuenta_uuid}>
              Propietario: {cuenta_name}
            </a>
          ) : (
            <>Propietario: {cuenta_name}</>
          )}
        </h6>

        <h3 className="font-lato-normal-500 pb-3">{parcela_name || '-'}</h3>

        <h6 className="font-lato-normal-400">
          {localidad}, {municipio}
        </h6>
      </div>
      <div className="col-xl-2 col-md-2"></div>
    </>
  );
};

export default CardTitleParcela;
