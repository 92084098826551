import React, {useState} from 'react';
import TextField from '@mui/material/TextField';

const FormInputMaterialTest = ({ register, error = false, label, id, ...inputProps }) => {
  const [shrink, setShrink] = useState(false);
  const height = 44;
  return (
    <>
        {inputProps.type !== "hidden" && <label htmlFor={id}>{label}</label>}
        <TextField
          id={id}
          type={inputProps.type || 'text'}
          className={`form-control ${error ? "_is-invalid" : ""}`}
          onFocus={() => setShrink(true)}
          onChangeCapture={(e) => setShrink(!!e.target.value)}
          variant={inputProps.variant || "outlined"}
          error={error.message ? true : false}
          style={{ height }}
          label={inputProps.placeholder || inputProps.label}
          {...register(id)}
          multiline={inputProps.multiline || false}
          InputLabelProps={{
            shrink: !!inputProps.value || shrink,
            style: {
              height,
              lineHeight: '0.85rem',
            },
          }}
      
          /* styles the input component */
          inputProps={{
              style: {
                height,
                padding: '0 10px',
              },
          }}
        />
      {error && <div className="invalid-feedback">{error.message}</div>}
    </>
  );
};
export default FormInputMaterialTest;
