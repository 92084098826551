import { useState } from 'react';
import Api from '../api/global.service';
import tokml from 'tokml';
import download from 'downloadjs';

const useDownloadKml = () => {
  const [blocking, setBlocking] = useState(false);
  const downloadKml = (uuid, filename = 'parcela', area = 'N/A') => {
    if (uuid) {
      Api.get('parcela_geojson', uuid)
        .then(response => {
          const geojson = {
            ...response.data,
            properties: {
              ...response.data.properties,
              area: area,
            },
          };
          const kmlDocumentName = tokml(geojson, { documentName: filename });
          download(kmlDocumentName, `${filename}.kml`);
          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('e', e);
          setBlocking(false);
        });
    }
  };

  return {
    blocking,
    downloadKml,
  };
};

export default useDownloadKml;
