import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageSettings } from './../config/page-settings.js';
import { useAuth } from '../components/content/auth.jsx';
import BgSvg from '../components/svg/bg-svg.jsx';
import LineSvg from '../components/svg/line-svg.jsx';
import env from './../environment.js';
import { getRutaInicial } from '../components/content/coop-config.jsx';


class LoginV3 extends React.Component {
  static contextType = PageSettings;

  state = {
    email: env.appEnv === 'local' ? 'vvalencia@nuup.org' : '',
    password: env.appEnv === 'local' ? 'S1ri0_01' : '',
    error: '',
    type: 'password',
  };

  constructor(props) {
    super(props);

    this.showHide = this.showHide.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem(`${env.appName}-sesion`);
    localStorage.removeItem(`${env.appName}-user`);
    localStorage.removeItem(`${env.appName}-token`);
    localStorage.removeItem(`${env.appName}-coop-config`);

    const sidebarState = localStorage.getItem(`${env.appName}-sidebar`);
    if (!sidebarState) {
      this.context.handleSetPageSidebar(false);
      this.context.handleSetPageHeader(false);
      this.context.handleSetBodyWhiteBg(false);
    } else {
      this.context.handleSetPageSidebar(true);
      this.context.handleSetPageHeader(true);
      this.context.handleSetBodyWhiteBg(true);
    }
}

  handleSubmit(event) {
    event.preventDefault();
    this.props.auth
      .login(this.state)
      .then(response => {
        if (response) {
          if (response.permissions.filter(x => x.includes('PADRON_COMPLETO')).length != 0) {
            window.location = getRutaInicial('/dashboard');
          } else {
            window.location = getRutaInicial('/admin/cuentas/list');
          }
        }
      })
      .catch(error => {
        error.json().then(message => {
          if (message.email != null) {
            this.setState({ error: 'Introduzca una dirección de correo electrónico válida.' });
          } else if (message.password != null) {
            this.setState({ error: 'La contraseña debe contener al menos 6 caracteres.' });
          } else {
            this.setState({ error: 'Correo electrónico o contraseña incorrectos.' });
          }
        });
      });
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input',
    });
  }

  render() {
    return (
      <div className="login login-with-news-feed">
        <div className="news-feed">
          <div className="news-image">
            <img
              src={env.images.login.bg_inicio_back}
              style={{ position: 'absolute', bottom: '20%', right: 0, zIndex: 0 }}
            />
            <BgSvg
              style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%', height: 'auto' }}></BgSvg>
            <img
              src={env.images.login.bg_inicio_front}
              style={{ position: 'absolute', bottom: 0, left: 0, width: '80%' }}
            />
            <div className="text-center login-title-container">
              <h1 className="login-title-text font-forum-normal-400">
                Transforma tus datos en oportunidades para tu organización
              </h1>
              <LineSvg style={{ marginTop: '40px' }}></LineSvg>
            </div>
          </div>

          {env.appEnv != 'prod' && (
            <div className="news-caption">
              <h4 className="caption-title">
                <b>{env.appName}</b> Admin v{env.appVersion} --{env.appEnv}
              </h4>
            </div>
          )}
        </div>
        <div className="right-content">
          <div className="login-header">
            <div className="brand">
              <img src={env.images.logo} />
            </div>
            <div className="icon">
              <i className="fa fa-sign-in"></i>
            </div>
          </div>
          <div className="login-content">
            <form className="margin-bottom-0" id="frmLogin" onSubmit={this.handleSubmit}>
              <div className="input-group m-b-15">
                <span className="input-group-text">
                  <i className="fa fa-user-circle"></i>
                </span>
                <input
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                  className="form-control form-control-lg"
                  placeholder="Correo electrónico"
                  required
                />
              </div>

              <div className="input-group m-b-15">
                <span className="input-group-text">
                  <i className="fa fa-lock"></i>
                </span>
                <input
                  type={this.state.type}
                  name="password"
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                  className="form-control form-control-lg"
                  placeholder="Contraseña"
                  required
                />
                <button type="button" className="btn btn-sm" onClick={this.showHide}>
                  {this.state.type === 'input' ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
                </button>
              </div>

              <div className="form-group m-b-15">
                <span style={{ color: 'red' }}>{this.state.error}</span>
              </div>

              <div className="login-buttons">
                <button type="submit" className="btn btn-block btn-lg btn-sirio" style={{ width: '50%' }}>
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default props => {
  const auth = useAuth();
  const history = useHistory();
  return <LoginV3 auth={auth} history={history} {...props} />;
};
