import React from 'react';
import { ReactComponent as NorthIconSvg } from '../../assets/images/icons/north-icon.svg';

const NorthIcon = ({ color = 'black' }) => (
  <NorthIconSvg
    fill={color}
    style={{
      height: '30px',
      position: 'absolute',
      top: '10px',
      left: '18px',
      zIndex: 1000,
    }}
    alt="North"
  />
);

export default NorthIcon;
