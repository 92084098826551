import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import * as yup from 'yup';
import { publish } from '../events';
import { useCoopConfig } from '../content/coop-config.jsx';

const ModalPrecio = forwardRef((props, ref) => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();

  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);

  const [cantidad, setCantidad] = useState('');

  const [errors, setErrors] = useState(null);
  const schema = yup.object().shape({
    cantidad: yup
      .number()
      .required('Debe escribir una cantidad')
      .positive(),
  });

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
  }));

  const showHandler = () => {
    setShow(true);
    setCantidad('');
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const validateHandler = () => {
    var data = {
      cantidad: cantidad,
    };
    schema
      .validate(data, { abortEarly: false })
      .then(function(valid) {
        //Datos validos
        const data = {
          precio_por_kg: parseFloat(cantidad * 100),
          id_cooperativa: auth.user.id_cooperativa,
        };

        setBlocking(true);

        Api.Acopio.create('registro_precio', data)
          .then(response => {
            if (response.data.success) {
              setBlocking(false);
              setShow(false);
              publish('Precio::onSave', response.data.data);
            } else {
              //Ocurrio un error
            }
          })
          .catch(e => {
            setBlocking(false);
          });
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
      });
  };

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div className="modal-dialog" style={{ top: 50, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 100px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit' }}>
            <h4 className="modal-title">{props.title}</h4>
            <button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row my-4">
              <div className="col-12">
                <span>
                  El precio se va sugerir a los acopiadores a partir de la fecha de registro hasta que se ingrese un nuevo precio.
                </span>
              </div>
            </div>

            <div className="row my-4">
              <div className="col-12">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <i className="fa fa-search" style={{ padding: 14, color: '#ffffff' }}></i>
                  </div>
                  <input
                    type="number"
                    name="cantidad"
                    className="form-control"
                    placeholder={`(${coopConfig.currency()?.symbol}) Monto de precio`}
                    value={cantidad}
                    onChange={event => {
                      setCantidad(event.target.value);
                    }}
                  />
                  <div className="input-group-append">
                    <i className="fa fa-chevron-down" style={{ padding: 14, color: '#ffffff' }}></i>
                  </div>
                </div>
                {errors?.cantidad && <div className="invalid-feedback">{errors?.cantidad}</div>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
            {!blocking && (
              <>
                <button
                  onClick={() => closeHandler()}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
                <button
                  onClick={() => validateHandler()}
                  type="button"
                  className="btn btn-outline-secondary btn-sirio"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-save"></i> Guardar
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalPrecio;
