import React, { useState, useEffect, createRef } from 'react';
import CardContainer from '../../../components/card/card-container.jsx';
import Api from '../../../api/global.service';
import { useAuth } from '../../../components/content/auth.jsx';
import env from '../../../environment.js';
import BlockUi from 'react-block-ui';
import LabelCategory from '../../../components/label/label-category.jsx';
import { publish } from '../../../components/events.js';
import formatNumber from '../../../utils/formatNumber.js';
import { useCoopConfig } from '../../../components/content/coop-config.jsx';

const EntregasXProductorInfo = props => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [blocking, setBlocking] = useState(true);
  const [cuenta, setCuenta] = useState([]);
  const [fotoURL, setFotoURL] = useState(false);
  const [fotoOpen, setFotoOpen] = useState(false);
  const [entregas, setEntregas] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [creditos, setcreditos] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Api.get('admin/acopios/entregas/acopio_por_productor', props.id_acopio)
      .then(response => {
        setBlocking(false);
        if (typeof response.data !== 'undefined') {
          let _cuenta = {
            cuenta_categoria: response.data[0].cuenta_categoria,
            cuenta_categoria_color: response.data[0].cuenta_categoria_color,
            productor_clave_tx: response.data[0].productor_clave_tx,
            productor_codigo_tx: response.data[0].productor_codigo_tx,
            productor_id: response.data[0].productor_id,
            productor_nombre: response.data[0].productor_nombre,
            productor_uuid: response.data[0].productor_uuid,
            region: response.data[0].region,
            localidad: response.data[0].localidad,
            municipio: response.data[0].municipio,
          };

          let _entregas = {
            entregas_no: response.data[0].entregas_no,
            entregas_no_bultos: response.data[0].entregas_no_bultos,
            entregas_peso_neto_total: response.data[0].entregas_peso_neto_total,
            precio_por_kg_promedio: response.data[0].precio_por_kg_promedio,
          };

          let _pagos = {
            pagos_descuentos_total: response.data[0].pagos_descuentos_total,
            pagos_pagado_total: response.data[0].pagos_pagado_total,
            pagos_total: response.data[0].pagos_total,
          };

          setCuenta(_cuenta);
          setEntregas(_entregas);
          setPagos(_pagos);
          publish('Entrega::Cuenta', _cuenta);
          publish('Entrega::Entregas', _entregas);
          publish('Entrega::Pagos', _pagos);
        }
        setBlocking(false);
      })
      .catch(e => {
        console.log('e', e);
        setBlocking(false);
      });
  };

  const getSaldoEntregas = (pagos_total, pagos_descuentos_total, pagos_pagado_total) => {
    return formatNumber((pagos_total || 0) - (pagos_descuentos_total || 0) - (pagos_pagado_total || 0));
  };

  return (
    <div className="col-xl-12 col-lg-12" style={{ backgroundColor: '#fcfcfc' }}>
      <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
        <div className="row">
          <div className="col-xl-5 col-lg-5 text-center">
            {fotoURL ? (
              <button type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }} onClick={() => setFotoOpen(!fotoOpen)}>
                <img src={`${env.apiURL}/${fotoURL}`} className="profile_productor"></img>
              </button>
            ) : (
              <img src={env.images.profile.productor} />
            )}
          </div>
          <div className="col-xl-6 col-lg-5 offset-1">
            <div className="row" style={{ paddingTop: 16 }}>
              <div className="col-xl-8 col-lg-8">
                <h6 className="font-lato-normal-400">Productor:</h6>
                <h4 className="font-lato-normal-500 pb-3">{cuenta?.productor_nombre}</h4>
                <LabelCategory label={cuenta?.cuenta_categoria} color={cuenta?.cuenta_categoria_color}></LabelCategory>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-xl-5 col-lg-5 offset-1">{cuenta?.productor_clave_tx}</div>
          <div className="col-xl-6 col-lg-6">{cuenta?.municipio}</div>
        </div>

        <div className="row">
          <div className="col-xl-5 col-lg-5 offset-1">{cuenta?.productor_codigo_tx}</div>
          <div className="col-xl-6 col-lg-6">{cuenta?.localidad}</div>
        </div>

        <div className="btn-entregas-acopios">Información de la entrega</div>

        <div className="pl-4 pt-3">
          <div className="row">
            <div className="col-xl-8 info-entregas-titulo">{'Entrega'}</div>
            {/*
                  <div className='col-xl-4 info-entregas-link'>
                    {'Ver recibos'}
                  </div>
                */}
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Nº bultos'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">{entregas?.entregas_no_bultos}</div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Peso neto total'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {formatNumber(entregas?.entregas_peso_neto_total || 0) + ' ' + coopConfig.acopioUnits()?.abreviacion_plural}
            </div>
          </div>
        </div>

        <div className="pl-4 pt-3">
          <div className="row">
            <div className="col-xl-12 info-entregas-titulo">{'Pago'}</div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Precio de pago'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {coopConfig.currency()?.symbol + ' ' + formatNumber(entregas?.precio_por_kg_promedio || 0)}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Importe bruto'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {coopConfig.currency()?.symbol +
                ' ' +
                formatNumber((entregas?.precio_por_kg_promedio || 0) * (entregas?.entregas_peso_neto_total || 0))}
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8 info-entregas-variable">{'Descuentos deudas'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {coopConfig.currency()?.symbol + ' ' + formatNumber(pagos?.pagos_descuentos_total || 0)}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Pagado a productor'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {coopConfig.currency()?.symbol + ' ' + formatNumber(pagos?.pagos_pagado_total || 0)}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-7 info-entregas-variable">{'Pago pendiente'}</div>
            <div className="col-xl-4 col-lg-5 info-entregas-variable">
              {coopConfig.currency()?.symbol +
                ' ' +
                getSaldoEntregas(pagos?.pagos_total || 0, pagos?.pagos_descuentos_total || 0, pagos?.pagos_pagado_total || 0)}
            </div>
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

export default EntregasXProductorInfo;
