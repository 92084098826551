import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelColor from '../../components/label/label-color.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import LabelDate from '../../components/label/label-date.jsx';
import ModalCreditoProductor from '../../components/modal/modal-credito-productor.jsx';
import ModalCreditoProductorAbono from '../../components/modal/modal-credito-productor-abono.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import Swal from 'sweetalert2';
import NoPagination from '../../components/pagination/noPagination.jsx';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import { subscribe, unsubscribe } from '../../components/events';
import { useCoopConfig, getIdCatCreditoPrecosecha } from '../../components/content/coop-config.jsx';
import transformWord from '../../utils/transformWord.js';
import DebtCalculator from '../../nuup/utils/debtCalculator.js';
import xlsx from 'json-as-xlsx';
import moment from 'moment/moment.js';
import LinkDownload from '../../components/link/link-download.jsx';
import { Colors } from '../../constants/Colors.js';

const AnticiposXProductorList = () => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);
  const dialog = React.createRef();
  const dialogAbono = React.createRef();
  const [selectedData, setSelectedData] = useState({});
  const [etiquetaAnticipos, setEtiquetaAnticipos] = useState('-');

  useEffect(() => {
    getEtiquetaAnticipo();
  }, [coopConfig.config]);

  const getEtiquetaAnticipo = async () => {
    let etiqueta = await coopConfig.etiquetaAnticipos();
    setEtiquetaAnticipos(etiqueta);
  };

const uncheckAll = () => {
  setSelectedData({})
}

  const columns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        id: 'acopio_uuid',
        columns: [
          {
            Header: '',
            id: 'acopio_uuid',
            accessor: 'x',
            width: 25,
            Cell: ({ row }) => {
                return (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      name="entregas"
                      type="checkbox"
                      style={{ width: 20, height: 20, accentColor: Colors.primary_600 }}
                      value={row.original.acopio_uuid}
                      onChange={e => {
                        setSelectedData(() => {
                          return e.target.checked ? row.original : {};
                        });
                      }}
                      checked={(selectedData?.anticipo_uuid === row.original.anticipo_uuid) === true}
                    />
                  </div>
                );
            },
          },
          {
            Header: 'CLAVE',
            accessor: 'productor_clave_tx',
            sortable: true,
            minWidth: 50,
            width: 100,
            Cell: ({ value }) => {
              return (
                <p className="text-right" style={{ margin: 0 }}>
                  {value}
                </p>
              );
            },
          },
          {
            Header: 'CÓDIGO',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig.config?.usa_codigo_tx,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: ({ value, row }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.cuenta_categoria_color} />;
              } else {
                return value;
              }
            },
          },
        ],
      },
      {
        Header: transformWord(etiquetaAnticipos, ['plural', 'uppercase']),
        columns: [
          // {
          //   Header: 'FOLIO',
          //   accessor: 'folio',
          //   sortable: true,
          //   minWidth: 80,
          // },
          {
            Header: 'FECHA DESEMBOLSO',
            accessor: 'fecha_ejecucion',
            sortable: true,
            minWidth: 80,
            Cell: ({ value }) => <LabelDate value={value}></LabelDate>,
          },
          {
            Header: 'IMPORTE TOTAL',
            accessor: 'total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2} />,
          },
          {
            Header: `${coopConfig.currency()?.symbol} DESEMBOLSADO`,
            accessor: 'cantidad_anticipo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2} />,
          },
          {
            Header: 'INTERÉS ACUMULADO',
            accessor: 'total_interes',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={value === 0 ? 0 : 2} />,
          },

          {
            Header: 'TASA INTERÉS',
            accessor: 'tasa_interes',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value / 100} digits={value === 0 ? 0 : 2} suffix={'%'} />,
          },
          {
            Header: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADO',
            accessor: 'total_anticipos_recuperados',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>,
          },
          {
            Header: 'COMISIÓN',
            accessor: 'comision_efectivo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => (
              <LabelNumber
                value={row.original.pagos_og.reduce(
                  (sum, item) =>
                    sum + (Number(item.comision_efectivo) > 0) ? Number(item.comision_efectivo) / 100 : Number(item.comision_efectivo),
                  0
                )}
                digits={value === 0 ? 0 : 2}
                currency={false}
                currencyLabel={coopConfig.currency()?.symbol}
              />
            ),
          },
          {
            Header: 'SALDO',
            accessor: 'total_saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} digits={2} currencyLabel={coopConfig.currency()?.symbol} />,
          },
          {
            Header: 'ESTATUS',
            accessor: 'estatus',
            sortable: true,
            minWidth: 50,
            Cell: ({ row, value }) => <LabelColor label={value} color={row.original.estatus_color} />,
          },
          {
            Header: 'REGISTRO',
            accessor: 'registro',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              return (
                <p className="text-left" style={{ margin: 0 }}>
                  Registrado por {value} el {row.original.fecha_ejecucion}
                </p>
              );
            },
          },
          {
            Header: 'RECIBOS',
            accessor: 'recibo_pdf',
            sortable: true,
            Cell: ({ value, row }) => {
              return (
                <LinkDownload
                  url={`recibos/anticipo/descargar/${row.original.anticipo_uuid}`}
                  label="Descargar"
                  icon={env.images.icon.pdf}
                />
              );
            },
          },
        ],
      },
    ],
    [etiquetaAnticipos, selectedData]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },

      { value: 1, target: 'productor_clave_tx', label: 'CLAVE' },
      { value: 2, target: 'productor_codigo_tx', label: 'CÓDIGO' },
      { value: 3, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 4, target: 'localidad', label: 'LOCALIDAD' },
      { value: 5, target: 'region', label: 'REGIÓN' },
      { value: 6, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 7, target: 'cuenta_categoria', label: 'CATEGORÍA' },

      // { value: 3, target: 'folio', label: 'FOLIO RECIBIDO' },
      { value: 8, target: 'fecha_ejecucion', label: 'FECHA DESEMBOLSO' },
      { value: 9, target: 'total', label: 'IMPORTE TOTAL' },
      { value: 10, target: 'cantidad_anticipo', label: 'CAPITAL DESEMBOLSADO' },
      { value: 11, target: 'total_interes', label: 'INTERÉS ACUMULADO' },
      { value: 12, target: 'tasa_interes', label: 'TASA INTERÉS' },
      {
        value: 13,
        target: 'total_anticipos_recuperados',
        label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADO',
      },
      { value: 14, target: 'total_saldo', label: 'SALDO' },
      { value: 15, target: 'estatus', label: 'ESTATUS' },
      { value: 16, target: 'registro', label: 'REGISTRO' },
    ],
    []
  );

  useEffect(() => {
    if (ciclo.id) {
      dialog.current.setIdCiclo(ciclo.id);
    }
  }, [ciclo]);

  useEffect(() => {
    subscribe('Anticipo::onSave', e => handleSave(e));
    subscribe('AnticipoPago::onSave', e => handleSavePago(e));
    return () => {
      unsubscribe('Anticipo::onSave');
      unsubscribe('AnticipoPago::onSave');
    };
  }, []);

  const setFilter = value => {
    setCiclo(value.target);
    updateData(value.target.id);
  };

  const getEstatus = saldo => {
    if (Number(saldo) >= 0.01) {
      return { label: 'Vigente', color: '#e74c3c' };
    }
    return { label: 'Pasado', color: '#27ae60' };
  };

  const updateData = id_ciclo => {
    setBlocking(true);
    Api.Acopio.search('anticipos_por_productor', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    })
      .then(response => {
        const updatedData = response.data.map(anticipo => {
          // RETURN DATA WITH DEBT CALCULATION
          if (getIdCatCreditoPrecosecha() > 2) {
            const debtCalc = new DebtCalculator({
              credito: anticipo.credito,
              interestRate: +anticipo.tasa_interes,
              pagos: anticipo.pagos_og,
              stringDate: null,
            });
            const calc = debtCalc.getDebt();
            const estatus = getEstatus(calc.debtToday.pendingCapital / 100);
            return {
              ...anticipo,
              total_saldo: calc.debtToday.pendingCapital / 100,
              total: +calc.debtToday.totalInterest / 100 + +anticipo.cantidad_anticipo,
              total_interes: calc.debtToday.totalInterest / 100,
              estatus: estatus.label,
              estatus_color: estatus.color,
            };
          }
          // RETURN DATA WITH NO INTEREST RATE
          const estatus = getEstatus(+anticipo.cantidad_anticipo - +anticipo.total_anticipos_recuperados);
          return {
            ...anticipo,
            total: +anticipo.cantidad_anticipo,
            total_interes: 0,
            total_saldo: +anticipo.cantidad_anticipo - +anticipo.total_anticipos_recuperados,
            estatus: estatus.label,
            estatus_color: estatus.color,
            comision_efectivo: 999,
          };
        });
        setData(updatedData);
        setOriginalData(updatedData);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const exportarAnticipos = () => {
    let xlsData1 = [
      {
        sheet: 'Anticipos',
        columns: [
          { label: 'CLAVE', value: 'productor_clave_tx' },
          { label: 'CÓDIGO', value: 'productor_codigo_tx' },
          { label: 'NOMBRE PRODUCTOR', value: 'productor_nombre' },
          { label: 'LOCALIDAD', value: 'localidad' },
          { label: 'REGIÓN', value: 'region' },
          { label: 'CENTRO DE ACOPIO', value: 'centro_acopio' },
          { label: 'CATEGORÍA', value: 'cuenta_categoria' },
          // { label: 'FOLIO RECIBIDO', value: 'folio' },
          { label: 'FECHA DESEMBOLSO', value: 'fecha_ejecucion' },
          { label: 'IMPORTE TOTAL', value: 'total', format: `${coopConfig.currency()?.symbol}#,##0.00` },
          { label: 'CAPITAL DESEMBOLSADO', value: row => +row.cantidad_anticipo, format: `${coopConfig.currency()?.symbol}#,##0.00` },
          { label: 'INTERÉS ACUMULADO', value: 'total_interes', format: `${coopConfig.currency()?.symbol}0.00` },
          { label: 'TASA INTERÉS', value: row => +row.tasa_interes / 10000, format: '0.00%' },
          {
            label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADOS',
            value: row => +row.total_anticipos_recuperados,
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          {
            label: 'COMISIÓN',
            value: row =>
              row.pagos_og.reduce(
                (sum, item) =>
                  sum + (Number(item.comision_efectivo) > 0) ? Number(item.comision_efectivo) / 100 : Number(item.comision_efectivo),
                0
              ),
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          {
            label: 'SALDO',
            value: row => (row.total_saldo < 0.01 && row.total_saldo > -0.01 ? 0 : row.total_saldo),
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          { label: 'ESTATUS', value: 'estatus' },
          { label: 'REGISTRO', value: row => `Registrado por ${row.registro} el ${row.fecha_ejecucion}` },
        ],
        content: data,
      },
    ];

    let settings = {
      fileName: `Anticipos_${ciclo.id}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData1, settings);
  };

  const getBackgroundColor = (i, j) => {
    if (i == 1) {
      return 'rgb(247, 239, 231)';
    } else {
      switch (j) {
        case 1:
          return '#91918A';
        case 3:
          return '#91918A';
        case 5:
          return '#91918A';
        case 7:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i == 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  const handleSave = event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se guardó con éxito el crédito ${event.detail.folio}.`,
    });
    setSelectedData({});
    updateData(event.detail.id_ciclos);
  };

  const handleSavePago = event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se guardó con éxito el abono al credito.`,
    });
    setSelectedData({});
    updateData(event.detail.id_ciclo);
  };

  const openModalAbono = () => {
    dialogAbono.current.setData(selectedData);
    dialogAbono.current.setIdAnticipo(selectedData.anticipo_id);
    dialogAbono.current.setIdCiclo(selectedData.id_ciclo);
    dialogAbono.current.show();
  };

  return (
    <div>
      <ModalCreditoProductor
        ref={dialog}
        title={'Registrar ' + transformWord(etiquetaAnticipos, ['singular']) + ' precosecha'}></ModalCreditoProductor>
      <ModalCreditoProductorAbono ref={dialogAbono} title="Abono de efectivo"></ModalCreditoProductorAbono>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Anticipos</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}
          acopio
        />
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
          <div className="col-2">
            {ciclo?.uuid && auth.hasPermission('ACOPIOS_ANTICIPOS_X_PRODUCTOR_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                {/*<LinkDownload
                  url={`xls/anticipos_acopios/${ciclo?.uuid}`}
                  label="Descargar Excel"
            icon={env.images.icon.xls}></LinkDownload>*/}
                <button onClick={() => exportarAnticipos()} className={`btn`} type="button">
                  <img src={env.images.icon.xls} className="pr-1" />
                  <p style={{ color: '#789D16' }}>Descargar Anticipos</p>
                </button>
              </div>
            )}
          </div>
        </div>

        <NoPagination
          blocking={blocking}
          selected="id"
          columns={columns}
          data={data}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}></NoPagination>
        <button
          onClick={() => dialog.current.show()}
          type="button"
          className="btn btn-sirio"
          style={{ borderRadius: '20px 20px 20px 20px', marginLeft: 10 }}>
          <i className="fa fa-plus"></i> {'Nuevo ' + transformWord(etiquetaAnticipos, ['singular'])}
        </button>

        <button
          onClick={() => openModalAbono()}
          type="button"
          className="btn btn-outline-secondary"
          style={{ borderRadius: '20px 20px 20px 20px', marginLeft: 10 }}
          disabled={!selectedData || Number(selectedData?.total_saldo) < 0.01}>
          <i className="fa fa-plus"></i> Registrar abono efectivo
        </button>
      </CardContainer>
    </div>
  );
};

export default AnticiposXProductorList;
